import { ClientApi } from '../../api/client-api';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { ViewContactSummary } from '../../api/dealer-api-interface-client';
import { EventContactOpen } from '../data/events';
// eslint-disable-next-line import/named
import { EventSnippet } from '../../components/ui/events';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { resolveURL } from '../../components/ui/resource-resolver';
import { resourceClient } from '../../dealer-franchisee/clients/ui/launcher';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { customElement } from 'lit/decorators.js';

interface ContactSummaryTableBaseOptions {
  title: EventSnippet;
}
@customElement('wm-contactsummarytablebase')
class ContactSummaryTableBase extends DataTableWrapper<ViewContactSummary> {
  eventTitle: EventSnippet;
  api: ClientApi = getApiFactory().client();
  filter: string | null;
  clientIdFilter: string | null;

  constructor(options: ContactSummaryTableBaseOptions) {
    super();
    this.eventTitle = options.title;
    this.filter = null;
    this.clientIdFilter = null;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  updateClientFilter(_clientId: string | null) {
    this.clientIdFilter = _clientId;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewContactSummary>> {
    const results = await this.api.browseContactSummary({
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      sortField: request.sortField,
      sortAsc: !request.sortAsc,
      clientOwnerId: userDataStore.clientOwnerId,
      filter: this.filter,
      clientId: this.clientIdFilter
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    return results.contactSummary;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  useAutoWidthColumns(): boolean {
    return false;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%contact%%`,
        width: '250px',
        data: 'name',
        className: 'contact-name'
      },
      {
        title: tlang`Primary`,
        width: '250px',
        data: 'isPrimary',
        orderable: false,
        className: 'contact-isprimary',
        render: (data: boolean) => this.isPrimary(data)
      },
      {
        title: tlang`%%client%%`,
        width: '250px',
        data: 'clientName',
        className: 'contact-client-name'
      },
      {
        title: tlang`Email`,
        width: '250px',
        orderable: false,
        data: 'email',
        className: 'contact-email'
      },
      {
        title: tlang`Role`,
        width: '250px',
        orderable: false,
        data: 'title',
        className: 'contact-title'
      },
      {
        title: tlang`Phone`,
        width: '250px',
        orderable: false,
        data: 'mobile',
        className: 'contact-mobile'
      }
    ];
  }

  protected isPrimary(data: boolean) {
    return data ? `<icon-checked />` : '';
  }
}

interface ContactSummaryTableOptions extends ContactSummaryTableBaseOptions {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}
@customElement('wm-contactsummarytable')
export class ContactSummaryTable extends ContactSummaryTableBase {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;

  constructor(options: ContactSummaryTableOptions) {
    super({ title: options.title });
    this.openContactEvent = options.openContactEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  bindClickEvents($dataTable: any) {
    //Data tables uses JQuery click events to bind things. they don't have any other
    //special handler.

    $dataTable.on(
      'click',
      '.contact-link',
      this.eventHandler(async (data: ViewContactSummary) => {
        await this.openContact(data);
      })
    );
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%contact%%`,
        width: '250px',
        data: 'id',
        className: 'contact-name',
        render: (data: string, _type: string, row: ViewContactSummary) => {
          return `<a class="contact-link" data-contact-id=${data} href="#">${row.name}</a>`;
        }
      },
      {
        title: tlang`Primary`,
        width: '250px',
        data: 'isPrimary',
        orderable: false,
        className: 'contact-is-primary',
        render: (data: boolean) => this.isPrimary(data)
      },
      {
        title: tlang`%%client%%`,
        width: '250px',
        data: 'clientId',
        className: 'contact-client-name',
        render: (data: string, _type: string, row: ViewContactSummary) => {
          return `<a class="client-link" data-contact-id=${data} href="${resolveURL(
            resourceClient,
            row.clientId
          )}">${row.clientName}</a>`;
        }
      },
      {
        title: tlang`Email`,
        width: '250px',
        orderable: false,
        data: 'email',
        className: 'contact-email'
      },
      {
        title: tlang`Role`,
        width: '250px',
        orderable: false,
        data: 'title',
        className: 'contact-title'
      },
      {
        title: tlang`Phone`,
        width: '250px',
        orderable: false,
        data: 'mobile',
        className: 'contact-mobile'
      }
    ];
  }

  private async openContact(viewContactSummary: ViewContactSummary): Promise<void> {
    await this.openContactEvent?.(viewContactSummary);
  }
}

//We can extend this to add options like pick immediately, and what buttons to show.
interface ContactSummaryPickerOptions extends ContactSummaryTableBaseOptions {
  clientId?: string;
  onContactSelect?: (contactSummary: ViewContactSummary) => void;
}
@customElement('wm-contactsummarypickerbrowser')
export class ContactSummaryPickerBrowser extends ContactSummaryTableBase {
  onContactSelect?: (contactSummary: ViewContactSummary) => void;

  constructor(options: ContactSummaryPickerOptions) {
    super({ title: options.title });
    this.clientIdFilter = options.clientId ?? null;
    this.onContactSelect = options.onContactSelect;
  }

  bindClickEvents($dataTable: any) {
    $dataTable.on(
      'click',
      'tr',
      this.eventHandler(async (data: ViewContactSummary) => {
        if (this.onContactSelect) this.onContactSelect?.(data);
      })
    );
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%contact%%`,
        width: '250px',
        data: 'id',
        render: (_data: string, _type: string, row: ViewContactSummary) => {
          return `${row.name}`;
        }
      },
      {
        title: tlang`Primary`,
        width: '250px',
        data: 'isPrimary',
        orderable: false,
        render: (data: boolean) => this.isPrimary(data)
      },
      {
        title: tlang`%%client%%`,
        width: '250px',
        data: 'clientName'
      }
    ];
  }
}
