import { information } from '../components/ui/modal-option';
import { tlang } from '@softtech/webmodule-components';
import { QuoteSupplier } from '../quotes/quote-service';

export function supplierOnline() {
  _supplierOnline = true;
}
let _supplierOnline = true;
let _supplierWasOffLine = false;
export function wasSupplierOffline(): boolean {
  const wof = _supplierWasOffLine;
  _supplierWasOffLine = !_supplierOnline;
  return wof;
}
let _displaying = false;

async function _displayOfflineMsg(aEvent: () => Promise<void>) {
  if (_displaying) return;
  _displaying = true;
  try {
    _supplierWasOffLine = true;
    if (!_supplierOnline) return;
    _supplierOnline = false;
    await aEvent();
  } finally {
    _displaying = false;
  }
}

export async function displaySupplierOffUpgrade() {
  await _displayOfflineMsg(async () => {
    await information(
      tlang`${'ref:supplier-offline-upgrading'}The %%supplier%% System is current OFFLINE performing an Upgrade. This should normally take no
        more than one hour. Please contact support ASAP to resolve this issue if it does not come back online after one hour.`,
      tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
    );
  });
}

export async function displaySupplierOffLineMsg() {
  await _displayOfflineMsg(async () => {
    await information(
      tlang`${'ref:supplier-offline-msg'}The activity you are trying to do is being blocked because the %%supplier%% system is OFFLINE.
                     Please contact support ASAP to resolve this issue. We recommend closing down what you are doing until this is resolved`,
      tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
    );
  });
}

export async function displayFrameConfigLoadErrorMsg() {
  await information(
    tlang`${'ref:supplier-frameconfig-offline-msg'}The activity you are trying to do is being blocked because the %%supplier%% %%frame%% config could not be loaded.
                     Please contact support ASAP to resolve this issue. We recommend closing down what you are doing until this is resolved`,
    tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
  );
}

let _displaySupplierTACNotApprovedMsg = false;
export async function displaySupplierTACNotApprovedMsg(supplier: QuoteSupplier, force?: boolean) {
  if (!force && _displaySupplierTACNotApprovedMsg) return;
  _displaySupplierTACNotApprovedMsg = true;
  await information(tlang`${'ref:tac-approval-not-set-msg'}The %%supplier%% (${supplier.description}) Terms and Conditions 
    have not been accepted by the %%franchisee%%. They will still need to accept the terms and conditions before using this feature`);
  return false;
}

export async function displaySupplierIncompatibleMsg(msg: string) {
  await information(
    tlang`${'ref:supplier-offline-incompatible-msg'}The activity you are trying to do is being blocked because the %%supplier%% system is OFFLINE or Incompatible.
                     Please contact support ASAP to resolve this issue. We recommend closing down what you are doing until this is resolved. ${msg}`,
    tlang`${'ref:supplier-offline-title'}%%supplier%% OFFLINE`
  );
}
