import { getApi, getApiFactory } from '../../api/api-injector';

import { Branch, Franchisee, FranchiseeConfiguration, TaxRate } from '../../api/dealer-api-interface-franchisee';
import { MultiPromise } from '../../multicast-promise';
import { NullPromise } from '../../null-promise';
import { DevelopmentError } from '../../development-error';
import { currentQuoteSupplierProvider } from '../../quotes/data/quoteSupplierProvider';
import {
  claimIdentity,
  getCurrentUser,
  setCurrentUser,
  setUserClaims,
  userSecurity
} from '@softtech/webmodule-components';
import {
  FranchiseeConfigData,
  IFranchiseeDataAdaptor,
  setFranchiseeDataAdaptor
} from '../../franchisee/data/franchisee-data-adaptor';
import { emptyGuid } from '../../api/guid';
import { flushCache } from '../cache/cache-registry';
import { updateCurrentUserClaims } from '../../components/currentuser-claims';
import { setSupplierReferenceOverride } from './v6/supplier-reference-override';
import { v6BootLoader } from '../../v6config/v6config';
import { isMultiSupplier } from '../../quotes/quote-service';
import { getSupplier } from '../quotes/supplier-selector';
import { displaySupplierTACNotApprovedMsg } from '../../v6config/supplier-offline';

const branchIdKey = 'webmodule-branchid';

export class CurrentUserDataStore implements IFranchiseeDataAdaptor {
  private _v6OverrideKey?: string;
  public async setV6OverrideKey() {
    const aKey = this._defaultBranchId;
    if (aKey !== this._v6OverrideKey) {
      if (await v6BootLoader()) {
        await setSupplierReferenceOverride(aKey);
        this._v6OverrideKey = aKey;
      }
    }
  }

  async singleSupplierTACApproved(): Promise<boolean> {
    if (isMultiSupplier()) return true;
    const supplier = await getSupplier();
    if (!this.supplierTACApproved(supplier.supplierId)) {
      await displaySupplierTACNotApprovedMsg(supplier, true);
      return false;
    }
    return true;
  }
  hasBranch(branchId: string): boolean {
    return this.allowAllBranchAccess && this._branches?.find(x => x.id === branchId) !== undefined;
  }
  private _systemDefaultBranchId: string = emptyGuid;
  //IFranchiseeDataAdaptor
  getFranchisee(): Franchisee {
    return this.franchisee;
  }

  public supplierTACApproved(supplierId: string): boolean {
    this.checkLoaded();
    const key = `supplier-${supplierId}-tac`;
    return this.franchisee.properties[key] === 'true';
  }
  async setFranchisee(franchisee: Franchisee): NullPromise<Franchisee> {
    const result = await getApiFactory().franchisee().updateFranchisee({ franchisee: franchisee });
    if (result && franchisee.id === this.franchisee.id) {
      this._franchisee = result.franchisee;
    }
    return result?.franchisee ?? null;
  }
  getFranchiseeConfig(): FranchiseeConfigData {
    return {
      franchiseeConfiguration: this.config,
      taxRates: this.taxRates
    };
  }
  async setFranchiseeConfig(franchiseeConfig: FranchiseeConfigData): NullPromise<FranchiseeConfigData> {
    const result = await getApiFactory().franchisee().updateFranchiseeConfiguration({
      franchiseeConfiguration: franchiseeConfig.franchiseeConfiguration,
      taxRates: franchiseeConfig.taxRates
    });
    if (result) {
      this._franchiseeConfig = result.franchiseeConfiguration;
      this._taxRates = result.taxRates;
      return {
        franchiseeConfiguration: result.franchiseeConfiguration,
        taxRates: result.taxRates
      };
    }
    return null;
  }
  getUserBranches(): Branch[] {
    this.checkLoaded();
    return this._branches ?? [];
  }
  async getAllBranches(): Promise<Branch[]> {
    return (await getApiFactory().franchisee().getBranches())?.branches ?? [];
  }
  async setBranch(branch: Branch): Promise<Branch | null> {
    const api = getApiFactory().franchisee();
    const branchResult =
      branch.id === emptyGuid
        ? await api.createBranch({
            abbreviation: branch.abbreviation,
            taxRegistrationNumber: branch.taxRegistrationNumber,
            billingAddress: branch.billingAddress,
            name: branch.name,
            physicalAddress: branch.physicalAddress,
            id: null
          })
        : await api.updateBranch({
            branch: branch
          });
    if (branchResult && this._branches) {
      const idx = this._branches.findIndex(x => x.id === branchResult.id);

      if (idx > -1) {
        this._branches[idx] = branchResult;
      } else if (this.allowAllBranchAccess) this._branches.push(branchResult);
      return branchResult;
    }

    return null;
  }
  public get allowAllBranchAccess(): boolean {
    return userSecurity().claimIsTrue(claimIdentity.allBranches);
  }
  //IFranchiseeDataAdaptor
  loaded = false;
  private _branches: Branch[] | null = null;
  private _defaultBranchId = '';
  private _franchiseeConfig: FranchiseeConfiguration | null = null;
  private _taxRates: TaxRate[] | null = null;
  private _franchisee: Franchisee | null = null;

  public get defaultTaxRate(): number {
    if (this._taxRates && this._taxRates.length > 0) return this._taxRates[0].rate;
    return 0;
  }
  public get franchisee(): Franchisee {
    this.checkLoaded();
    if (!this._franchisee) throw new DevelopmentError('Franchisee is null');
    return this._franchisee;
  }

  public get config(): FranchiseeConfiguration {
    this.checkLoaded();
    if (!this._franchiseeConfig) throw new DevelopmentError('Franchisee is null');
    return this._franchiseeConfig;
  }

  public get taxRates(): TaxRate[] {
    this.checkLoaded();
    if (!this._taxRates) throw new DevelopmentError('Franchisee rates are null');
    return this._taxRates;
  }

  get clientOwnerId() {
    return this.defaultBranch.id;
  }
  public get defaultBranch(): Branch {
    this.checkLoaded();

    const branch = this._branches?.find(x => x.id == this._defaultBranchId) ?? null;
    if (!branch) throw new DevelopmentError('Default Branch is null');
    return branch;
  }
  public set defaultBranch(value: Branch) {
    const b = this._branches?.find(x => x.id === value.id);
    //cannot reset default branch without this privelage
    if (!this.allowAllBranchAccess || !b) {
      this.internalSetDefaultBranch(this._systemDefaultBranchId);
      return;
    }
    this.internalSetDefaultBranch(b.id);
  }
  public branchById(id: string): Branch {
    if (!this._branches) throw new DevelopmentError('load branches before trying to access them');
    const b = this._branches.find(x => x.id === id);
    if (!b) throw new Error(`Branch ${id} does not exist`);
    return b;
  }
  private internalSetDefaultBranch(id: string) {
    if (id === this._defaultBranchId) return;
    let b = this.branchById(id);
    if (!b) {
      b = this.branchById(this._systemDefaultBranchId);
    }
    if (b) {
      this._defaultBranchId = id;
      localStorage.setItem(branchIdKey, id);
      flushCache();
    }
  }

  public get quoteSupplierProvider(): string {
    //TODO - this will eventually become a system provided value based on licensing.
    //for now we will hard code it.
    // change to "v7" for testing v6 frame engine and pricing engine integrations
    return currentQuoteSupplierProvider;
  }

  /**
   * This should only be called from LogOFF
   */
  clear() {
    this.loaded = false;
    this.loadCorePromise.reset();
    this.loadCoreDetailsPromise.reset();
    this._franchisee = null;
    this._branches = null;
    this._defaultBranchId = '';
    this._franchiseeConfig = null;
    this._systemDefaultBranchId = emptyGuid;
    this._taxRates = null;
    localStorage.removeItem(branchIdKey);
    //        this._userClaims = null;

    flushCache();
  }

  /**
   * This should called so the next await gets new data, without destroying in use data
   */
  requestRefresh() {
    this.loadCorePromise.reset();
    this.loadCoreDetailsPromise.reset();
  }
  /**
   * forces the cache to refresh
   * @returns a fresh copy of the franchisee
   */
  async reLoadFranchisee(): NullPromise<Franchisee> {
    this.requestRefresh();
    await this.loadCoreDetails();
    return this.franchisee;
  }

  private loadCoreDetailsPromise = new MultiPromise<void>(async () => {
    if (!getCurrentUser()) {
      const result = await getApi().performAuthenticationProcess();
      if (!result) {
        window.location.href = '/';
      }
      return;
    } else {
      try {
        await this.loadCorePromise.run();
      } catch {
        setTimeout(() => setCurrentUser(null), 500);
      }
    }
  }, false);

  private loadCorePromise = new MultiPromise<void>(async () => await this.performLoadCoreDetails(), false);

  async loadCoreDetailsAfterLogin() {
    await this.loadCorePromise.run();
  }

  async loadCoreDetails() {
    await this.loadCoreDetailsPromise.run();
  }

  private checkLoaded() {
    if (!this.loaded) throw new Error('CurrentUserDataStore has not been loaded');
  }

  private async performLoadCoreDetails() {
    const user = getCurrentUser();
    if (!user) throw new DevelopmentError('Authentication could not be performed');

    const api = getApiFactory().franchisee();
    const result = await api.getActiveUserInformation({
      systemUserId: user.id
    });
    if (result) {
      this._franchisee = result.franchisee;
      this._branches = result.branches;
      this._franchiseeConfig = result.franchiseeConfiguration;
      this._taxRates = result.taxRates;
      this._systemDefaultBranchId = result.defaultBranchId;
      setUserClaims(result.userClaims);
      updateCurrentUserClaims();
      this._defaultBranchId = this.configureDefaultBranch(result.defaultBranchId);
      this.loaded = true; // set before accessing V6
      await this.setV6OverrideKey();

      setFranchiseeDataAdaptor(this);
    } else throw new Error('could not load user details');
  }
  configureDefaultBranch(defaultBranchId: string): string {
    if (!this.allowAllBranchAccess) {
      localStorage.setItem(branchIdKey, defaultBranchId);
      return defaultBranchId;
    }

    const lastSelectedBranchId = localStorage.getItem(branchIdKey) ?? emptyGuid;
    const lastBranch = this._branches?.find(x => x.id === lastSelectedBranchId);
    return lastBranch?.id ?? defaultBranchId;
  }
}

export const userDataStore = new CurrentUserDataStore();
