import { ResourceLock } from '../../api/dealer-api-interface-franchisee';
import { serverDateTimeToLocalDateTime } from '../../components/datetime-converter';
import { information } from '../../components/ui/modal-option';
import { DevelopmentError } from '../../development-error';
import { lang, tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../null-promise';
import { getUserInfo, getUserInformativeDisplayName } from './cache-tools';
import { isOptimisticLockOwner, optimisticLock, optimisticLockRelease } from './optimistic-lock';
import { LockResourceResult } from '../../components/resource-lock-info';
import { isDebugMode } from '../../components/debug';

export interface ResourceLockAction {
  resourceId: string;
  resourceType: string;
  dictEntry: string;

  //this is when for one reason or another this browser session time is way over without performing any activity
  //such as a browser sleep in edge we cant control. in this case we must treat our data as state, and shut down.
  onLockRevoked: () => Promise<void>;
}

let locks: FranchiseeResourceLock[] = [];
let lockInterval: NodeJS.Timeout | null = null;

const intervalFunc = async () => {
  clearLockInterval();
  const locksCopy = locks.filter(() => true);

  try {
    for (let i = 0; i < locksCopy.length; i++) {
      const item = locksCopy[i];
      if (!item.isWorking) {
        //although this function is an async promise, setInterval itself doesn't care that its a promise
        //so we need to ensure that the interval doesn't reprocess the same entities if they are held
        //up for some reason.
        item.working();
        try {
          const valid = await item.acquireLockLoop();

          if (!valid) item.pop();
        } finally {
          item.notWorking();
        }
      }
    }
  } finally {
    if (locks.length > 0) createLockInterval();
  }
};
function createLockInterval() {
  if (!lockInterval) {
    lockInterval = setInterval(intervalFunc, 1000);
  }
}

function clearLockInterval() {
  if (lockInterval) clearInterval(lockInterval);
  lockInterval = null;
}

class FranchiseeResourceLock {
  isWorking = false;
  lockDiscarded = false;
  working() {
    this.isWorking = true;
  }
  notWorking() {
    this.isWorking = false;
  }
  lockExpiry: any;
  isLockOwner: boolean;
  lockOwnerName: string;
  lockOwnerEmail: string;
  actions: ResourceLockAction;
  resourceStr: string;
  constructor(actions: ResourceLockAction) {
    this.isLockOwner = false;
    this.lockOwnerName = '';
    this.lockOwnerEmail = '';
    this.actions = actions;
    this.resourceStr = lang('%%' + actions.dictEntry + '%%');
  }

  private async acquireLock(): NullPromise<ResourceLock> {
    const thislock = await optimisticLock(this.actions.resourceType, this.actions.resourceId);
    this.lockOwnerName = '';
    this.isLockOwner = isOptimisticLockOwner(thislock);
    if (thislock) {
      const info = await getUserInfo(thislock?.systemUserId);
      this.lockOwnerName = info?.friendlyName ?? '';
      this.lockOwnerEmail = info?.emailAddress ?? '';
      this.lockExpiry = serverDateTimeToLocalDateTime(thislock.optmisticLock);
    }
    return thislock;
  }

  private push() {
    locks.push(this);
    if (lockInterval === null) createLockInterval();
  }
  public pop() {
    locks = locks.filter(x => x !== this);
    if (locks.length === 0) clearLockInterval();
  }

  public async acquire(): Promise<boolean> {
    const lock = await this.acquireLock();

    if (lock === null) {
      console.log(`${this.resourceStr} Lock Failed: Reason Unknown`);
      await information(
        tlang`the ${this.resourceStr} could not be locked at this time, due to a communication error.`,
        tlang`Resource Lock Failed`
      );
      return false;
    } else if (lock != null) {
      if (this.isLockOwner) {
        console.log(`${this.resourceStr} Lock Success`);
        this.push();
      } else {
        console.log(`${this.resourceStr} Lock Failed: Reason Already Locked`);
      }
    }
    return true;
  }

  public async release(): Promise<boolean> {
    this.pop();
    if (this.isLockOwner) {
      try {
        await optimisticLockRelease(this.actions.resourceId);
      } catch {
        //we dont want to crash the workflow if lock release fails
      }
      console.log(`${this.resourceStr} Lock Released`);
    }
    return true;
  }

  public async acquireLockLoop(): Promise<boolean> {
    const seconds = this.lockExpiry.diffNow('seconds').seconds;
    if (seconds < 60) {
      if (seconds < 0 && !isDebugMode()) {
        console.log(`${this.resourceStr} Lock Abandoned: Timer over expected point ${Math.abs(seconds)} seconds. `);
        this.lockDiscarded = true;
        this.pop();
        await information(
          tlang`This browser has been asleep from lack of use, or without internet connectivity, and the ${this.resourceStr} will be abandoned now as it may have been changed by other users. Please reopen to make edits.`
        );
        await this.actions.onLockRevoked();
        return false;
      }
      //reaquire lock.
      const lock = await this.acquireLock();
      if (!this.isLockOwner) {
        if (lock == null) {
          this.pop();
          //if we reach here, there was a communication error with the server, so we dont want to close or abandon at this time.
          console.log(`${this.resourceStr} Lock Failed: Reason Unknown`);
          await information(
            tlang`There was an error reaquiring the lock on this ${this.resourceStr}. Please attempt to close and save now to avoid data loss.`
          );
          return false;
        } else {
          this.lockDiscarded = true;
          this.pop();
          console.log(`${this.resourceStr} Lock Failed: Lock Taken by another system`);
          await information(
            tlang`We attempted to maintain the lock on this ${
              this.resourceStr
            }, but a lock has been issued to ${await getUserInformativeDisplayName(
              lock.systemUserId
            )}.  Due to this we needed to abandon changes and close the ${
              this.resourceStr
            } now. you can try to reopen it, if the other user unlocks it.`
          );
          await this.actions.onLockRevoked();

          return false;
        }
      } else {
        console.log(`${this.resourceStr} Lock Refreshed`);
        return true;
      }
    } else return true;
  }
}

/**
 *
 * @param action
 */
export async function requestResourceAccess(action: ResourceLockAction): Promise<LockResourceResult> {
  const l = new FranchiseeResourceLock(action);
  const accessResource = await l.acquire();
  const isLockOwner = l.isLockOwner;
  return {
    canUseResource: accessResource,
    isLockOwner: isLockOwner,
    lockOwnerName: l.lockOwnerName,
    lockOwnerEmail: l.lockOwnerEmail
  };
}

/**
 *
 * @param action this must be the exact instance passed to requestResourceAccess. this will release the lock
 * and remove all timed refreshers
 */
export async function releaseResourceAccess(action: ResourceLockAction): Promise<void> {
  const l = locks.find(x => x.actions === action);
  if (l) {
    await l.release();
  }
}

export class ResourceLocker implements LockResourceResult {
  lockAction: ResourceLockAction;
  lockResult: LockResourceResult | null = null;
  constructor(id: string, restype: string, dictEntry: string, lockRevokedEvent: () => Promise<void>) {
    this.lockAction = {
      resourceId: id,
      resourceType: restype,
      dictEntry: dictEntry,
      onLockRevoked: lockRevokedEvent
    };
  }
  public get canUseResource(): boolean {
    if (!this.lockResult) throw new DevelopmentError('call lock before checking result');
    return this.lockResult?.canUseResource ?? false;
  }
  public get isLockOwner(): boolean {
    if (!this.lockResult) throw new DevelopmentError('call lock before checking result');
    return this.lockResult?.isLockOwner ?? false;
  }
  public get lockOwnerName(): string {
    if (!this.lockResult) throw new DevelopmentError('call lock before checking result');
    return this.lockResult?.lockOwnerName ?? '';
  }
  public get lockOwnerEmail(): string {
    if (!this.lockResult) throw new DevelopmentError('call lock before checking result');
    return this.lockResult?.lockOwnerEmail ?? '';
  }
  public async lock(): Promise<boolean> {
    this.lockResult = await requestResourceAccess(this.lockAction);
    return this.lockResult.canUseResource ?? false;
  }
  public async release(): Promise<void> {
    if (!this.lockResult) return;
    await releaseResourceAccess(this.lockAction);
    //we have had issues sometimes where the lock released and then code tried
    //to access it. a release lock is basically the same as not being lock owner
    //so this will give readonly access and no errors
    this.lockResult = {
      canUseResource: true,
      isLockOwner: false,
      lockOwnerName: '',
      lockOwnerEmail: ''
    };
  }
}
