import {
  Frame,
  NFRCItemValues,
  QuoteFrameItemPrice,
  V6QuoteItemSupplierInfo
} from '@softtech/webmodule-data-contracts';
import { QuoteItem, QuoteItemPrice } from '../../../api/dealer-api-interface-quote';
import { money } from '../../../components/currency-formatter';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { nfrcCalculator } from '../../../nfrc/nfrc-calculation';
import { v6Config, v6Util } from '../../../v6config/v6config';
import { isFrame } from '../quote-helper-functions';
import { QuoteItemContainer } from '../quote-item-container';
import { StockLookupView } from '../../../api/dealer-api-interface-franchisee';
import { quoteSupplierProvider } from '../quoteSupplierProvider';
import { toJsonStr, fromJsonStr } from '../../../blob/converters';
import { clone, compare } from '../../../components/clone';
import { QuoteItemContainerV6Data } from './quote-item-container-expanded-v6-data';

export function getV6QuoteItemCache(quoteItemContainer: QuoteItemContainer): QuoteItemContainerV6Data | undefined {
  if (!isV6(quoteItemContainer)) return undefined;
  if (!quoteItemContainer.data?.providerData) return undefined;
  if (!quoteItemContainer.clientSideCache) {
    const v6Item = isFrame(quoteItemContainer.item)
      ? fromJsonStr<object>(quoteItemContainer.data?.providerData)
      : undefined;

    const v6Frame = v6Item ? v6Util().getFrameData(v6Item) : undefined;
    const supplierInfo = v6Item ? v6Util().getQuoteItemSupplier(v6Item) : undefined;
    const cache: QuoteItemContainerV6Data = {
      frameData: v6Frame,
      supplier: supplierInfo,
      v6Item: v6Item
    };
    quoteItemContainer.clientSideCache = cache;
  }
  return quoteItemContainer.clientSideCache as QuoteItemContainerV6Data;
}
export function getV6QuoteItemForContainer(quoteItemContainer: QuoteItemContainer): object | undefined {
  return getV6QuoteItemCache(quoteItemContainer)?.v6Item;
}
export function getV6FrameForContainer(quoteItemContainer: QuoteItemContainer): Frame | undefined {
  return getV6QuoteItemCache(quoteItemContainer)?.frameData;
}
export function getV6SupplierForContainer(quoteItemContainer: QuoteItemContainer): V6QuoteItemSupplierInfo | undefined {
  return getV6QuoteItemCache(quoteItemContainer)?.supplier;
}

export function getQuantityCostWithBuyIn(netSellPrice: number, buyInCosts?: StockLookupView[]): number {
  //Buy in cost are for the whole BOM. We need to divide the sum by qty and add it to the single unit
  if (buyInCosts) {
    const buyInSum = buyInCosts.reduce((sum, b) => sum + (b.cost ?? 0), 0);
    return money(netSellPrice + buyInSum, 2);
  }

  //due to rounding errors on reports we have decided to round all single unit prices to 2dp, and then reset quantity price
  //to be a multiplier of this value.
  return money(netSellPrice, 2);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function applyV6PricesToQuoteItemPrice(
  quantity: number,
  price: QuoteFrameItemPrice,
  itemPrice: QuoteItemPrice,
  buyInCosts?: StockLookupView[]
) {
  /*

   grossSellPrice = grossSellPrice + grossPriceLab
   netSellPrice = netSellPrice + netSellPriceLab
   const examplePriceData = {
   costPrice: 228.58,
   costTax: 22.86, //tax to add
   overHead: 114.29,
   markup: 85.73,
   grossSellPrice: 428.6, // RRP
   itemDisc: 60,
   salesCommission: 0,
   priceAdj: 0,
   netSellPrice: 368.6, // after discounts and commisions and adjustments. Dealer Price
   tax: 184.3, //tax to add
   costPriceLab: 0,
   costTaxLab: 0,
   overHeadLab: 0,
   markupLab: 0,
   grossSellPriceLab: 0,
   itemDiscLab: 0,
   salesCommissLab: 0,
   priceAdjLab: 0,
   netSellPriceLab: 0,
   taxLab: 0,
   grossSellPriceLockAdj: 0,
   grossSellPriceLockAdjLab: 0,
   unRecoverableWaste: 0,
   totalSellingPriceAdjusted: 0,
   //Dealer Price + all taxes etc
   totalSellPrice: 575.76,
   };
   */

  //This is the V6 Frame Price for all qty..
  const netSellPriceQuantity = price.netSellPrice + price.netSellPriceLab;
  //Kyley- Removed the code that was altering values based on the
  //supplier adjustment being wrong.
  //if we want to do that, we should enforce it at a higher layer so
  //that we are not manipulating it different from what the supplier expects
  const grossSellPriceQuantity = price.grossSellPrice + price.grossSellPriceLab;

  // This is v6 price included with buyin data and rounded to 2dp
  const quantityUnitCostWithBuyIn = getQuantityCostWithBuyIn(
    netSellPriceQuantity + itemPrice.supplierPriceAdjustment,
    buyInCosts
  );

  const singleUnitCostWithBuyIn = money(quantityUnitCostWithBuyIn / quantity, 2);

  //remove the commonid and leave a dictionary of numbers for the raw data
  const { quoteItemCommonId, ...priceDict } = price;
  itemPrice.sourceData = priceDict;

  //set the single unit cost the to base the margin on
  itemPrice.singleUnitCost = singleUnitCostWithBuyIn; //2dp

  //reverse engineer the quantity cost. This wont match the v6 price anymore
  itemPrice.quantityCost = quantityUnitCostWithBuyIn;

  //this is the price that goes back to v6 generating this quote item again for supplier
  //this price now may have a reconciliation variance with the original v6 price due to
  //division but wont ever be more than 1cent
  itemPrice.supplierNettQuantityCost = money(netSellPriceQuantity, 2);

  //this is allocating the price the supplier is selling to the dealer
  itemPrice.supplierNettSingleUnitCost = money(itemPrice.supplierNettQuantityCost / quantity, 2);

  //This is changing in purpose, as the original intent of having taxes is redundant. we can look at raw source data
  //if needed
  //this value is storing the LISTRRP price
  //this is the price before Discount and SalesCommissions are applied
  itemPrice.supplierGrossQuantityCost = money(grossSellPriceQuantity, 2);
  //This is changing in purpose, as the original intent of having taxes is redundant. we can look at raw source data
  //if needed
  //this value is storing the LISTRRP price
  //this is the price before Discount and SalesCommissions are applied
  itemPrice.supplierGrossSingleUnitCost = money(itemPrice.supplierGrossQuantityCost / quantity, 2);
}

export function updateDealerFreehandPrice(quantity: number, price: QuoteItemPrice) {
  price.singleUnitCost = money(price.singleUnitCost, 2);
  //for now, quantityCost, supplierNettQuantityCost and supplierGrossQuantityCost should all 3 be the same value
  const qtyCost = money(price.singleUnitCost * quantity, 2);
  price.supplierNettSingleUnitCost = price.singleUnitCost;
  price.quantityCost = qtyCost;
  price.supplierNettQuantityCost = qtyCost;
  price.supplierGrossQuantityCost = qtyCost;
  price.supplierGrossSingleUnitCost = price.singleUnitCost;
  price.sourceData = {};
}

export function getV6QuoteItemNFRCData(calculationName: string, quoteItem: object /* V6QuoteItem */): NFRCItemValues[] {
  return v6Util().getNFRCData(quoteItem, nfrcCalculator(calculationName).requiredPublishedCodes);
}

/**
 * this wrapper may need to be turned into injected abstract code at some point
 */
export async function performSupplierHealthCheck() {
  await v6Config().performSupplierHealthCheck();
}

export async function performSupplierHealthCheckLazy() {
  await v6Config().performSupplierHealthCheckLazy();
}

export function isV6(quoteItemContainer: QuoteItemContainer | QuoteItem): boolean {
  const item = (quoteItemContainer as QuoteItemContainer).item ?? (quoteItemContainer as QuoteItem) ?? null;
  return item ? item.serviceProvider === quoteSupplierProvider.v6 : false;
}

export function isQuoteFrameVersionCurrent(quoteItemContainer: QuoteItemContainer): boolean {
  const supplierInfo = getV6SupplierForContainer(quoteItemContainer);
  if (isEmptyOrSpace(supplierInfo?.supplierId)) return false;
  return supplierInfo?.isVersionCurrent ?? false;
}

export function getQuoteFrameVersion(quoteItemContainer: QuoteItemContainer): string {
  const supplierInfo = getV6SupplierForContainer(quoteItemContainer);
  if (!supplierInfo) return '';
  if (isEmptyOrSpace(supplierInfo.supplierId)) return '';
  return supplierInfo.quoteItemVersion;
}

export function getSupplierVersion(supplierId: string): string {
  const supplier = v6Config().getSupplier(supplierId);
  return supplier.version;
}

export function isSupplierUnitsMetric(supplierId: string): boolean {
  const supplier = v6Config().getSupplier(supplierId);
  return supplier.isMetric;
}

export function isSupplierUsingSSI(supplierId: string): boolean {
  const supplierInfo = v6Config().getSupplier(supplierId);

  return supplierInfo.options?.['useServerSideItems'] === 'true';
}

export function updateQuoteItemContainer(
  itemContainer: QuoteItemContainer,
  v6QuoteItem: unknown,
  price: QuoteFrameItemPrice | null | undefined,
  buyInCosts?: StockLookupView[]
) {
  if (itemContainer.data) itemContainer.data.providerData = toJsonStr(v6QuoteItem) ?? null;

  //only update the data if we passed in a value, otherwise leave it in its current value
  if (buyInCosts) itemContainer.buyInData = toJsonStr(buyInCosts);

  if (itemContainer.price && price) {
    const quantity = itemContainer.item.quantity;
    const originalItemPrice = clone(itemContainer.price);
    applyV6PricesToQuoteItemPrice(quantity, price, itemContainer.price, buyInCosts);
    // if the pricing from V6 is different from what we originally had, clear any supplier price adjustment.
    if (!compare(originalItemPrice, itemContainer.price)) itemContainer.price.supplierPriceAdjustment = 0;
  }
  itemContainer.clientSideCache = undefined;
}
