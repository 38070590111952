import { html } from 'lit';
import { getApiFactory } from '../../api/api-injector';
import { Franchisee } from '../../api/dealer-api-interface-franchisee';
import { FranchiseeApi } from '../../api/franchisee-api';
import { isAutoSaving } from '../../components/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { EventTemplate, Snippet } from '../../components/ui/events';
import { tlang, userSecurity } from '@softtech/webmodule-components';
import { fireQuickSuccessToast, fireToastError } from '../../toast-away';
import { ImageUploader } from '../data/image-upload';
import { DataProvider, ProviderBackup } from '../../components/clone';
import { asMarkdownTemplate } from '../../components/markdown';
import { customElement } from 'lit/decorators.js';
@customElement('wm-franchiseebrandingview')
export class FranchiseeBrandingView extends PageControlTabWithIndependantSaving {
  private franchiseeApi: FranchiseeApi = getApiFactory().franchisee();
  private franchiseeBackup: ProviderBackup<Franchisee>;
  private dataBinding: DataBinding;
  private imageUploader: ImageUploader;

  constructor(franchiseeDataProvider: DataProvider<Franchisee>) {
    super();
    this.pageFragment = 'branding';
    this.franchiseeBackup = new ProviderBackup<Franchisee>(franchiseeDataProvider);

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.imageUploader = new ImageUploader({
      dataBinding: this.dataBinding
    });
  }

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  public isDataReadonly(): boolean {
    return !this.isAdmin;
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Branding`;
  }
  getValidationErrors(): string[] {
    const errors: string[] = [];

    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (
      this.imageUploader.elementHasFile('franchisee-positive-logo') &&
      !this.imageUploader.elementHasImageFile('franchisee-positive-logo')
    ) {
      errors.push(tlang`Please provide a valid Color Logo`);
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (
      this.imageUploader.elementHasFile('franchisee-negative-logo') &&
      !this.imageUploader.elementHasImageFile('franchisee-negative-logo')
    ) {
      errors.push(tlang`Please provide a valid White Logo`);
    }
    return errors;
  }

  async prepareForSave(): Promise<void> {
    //nothing to do;
  }
  public internalDataChanged(): boolean {
    return (
      this.imageUploader.elementHasFile('franchisee-positive-logo') ||
      this.imageUploader.elementHasFile('franchisee-negative-logo')
    );
  }

  async onEnter(): Promise<void> {
    this.franchiseeBackup.reset();
    this.requestUpdate();
  }

  get franchisee(): Franchisee {
    return this.franchiseeBackup.item;
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a logo from the given element and get its uploaded file path
    const newPositiveLogoPath = await this.uploadLogoFromElement('franchisee-positive-logo', 'positive');
    const newNegativeLogoPath = await this.uploadLogoFromElement('franchisee-negative-logo', 'negative');

    // use the new path of the logo if it was uploaded, or the existing franchisee logo path
    this.franchisee.positiveLogoVirtualPath = newPositiveLogoPath ?? this.franchisee.positiveLogoVirtualPath;
    this.franchisee.negativeLogoVirtualPath = newNegativeLogoPath ?? this.franchisee.negativeLogoVirtualPath;

    const result = await this.franchiseeBackup.commitChanges();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Branding saved`);
      return true;
    }

    return false;
  }

  /**
   * Attempts to upload a logo image from the specified element and returns its virtual file path if successful, or returns null if there is no logo to upload.
   * If successful, it removes the local file from the specified element.
   * @param fieldName The field name of the element that the file will be uploaded from.
   * @param newFileName The new name of the uploaded file.
   * @returns A promise with the file path if the file was uploaded, or null if it wasn't.
   */
  private async uploadLogoFromElement(fieldName: string, newFileName: string): Promise<string | null> {
    const fileInformation = await this.imageUploader.getVirtualFileFromElement(fieldName);
    // only attempt an upload if we have information about the image file to be uploaded.
    if (fileInformation) {
      // get the virtual logo path from the API and attempt to upload the file content.
      fileInformation.filePath = this.franchiseeApi.createBrandingLogoImagePath(newFileName, fileInformation.extension);
      const logoResult = await this.imageUploader.uploadVirtualFile(fileInformation);
      if (logoResult) {
        // if successful remove the local uploaded file to avoid repeated uploads.
        this.dataBinding.removeFiles(fieldName);
        return fileInformation.filePath;
      }
      fireToastError(new Error(tlang`Unable to upload Logo`));
    }
    // if we get here no logo image has been uploaded, so return null as the path.
    return null;
  }

  /**
   * Attempts to get the absolute virtual file path for a given relative file path.
   * @param relativeVirtualFilePath The relative virtual file path.
   * @returns The full absolute virtual file path.
   */
  private getAbsoluteVirtualFilePath(relativeVirtualFilePath: string | null | undefined): string {
    if (relativeVirtualFilePath) return this.franchiseeApi.api.fullUrl(`api/file/${relativeVirtualFilePath}`);
    else return '';
  }

  protected bodyTemplate(): EventTemplate {
    const fld = (name: string) => this.dataBinding.field(name);

    const brandingMsg = asMarkdownTemplate(tlang`${'ref:FranchiseeBrandingView:uploadMsg:1'}
Please upload your business logo<br>
This will be incorporated into documents sent to !!client!! and !!supplier!!, such as !!estimate!!/!!invoice!!/!!purchase-order!!.

Choose a logo to appear at the top of PDFs and reports you print or send.<br>
The minimum permitted size is **800(W) x 400(H)** pixels.<br>
The ideal size of your logo is **1600(W) x 800(H)** pixels.<br>
The image must be **300DPI** in quality and in the **jpg** format on a white background.<br>
If the aspect ratio of your logo is different, it will be scaled down in proportion to fit the template constraints.
`);

    return html` <div>
      <form id="CompanyBrandingForm" class="form-two-col">
        <div class="row branding-wrapper">
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">Colour Logo</h4>
            <div class="branding-image-description">This logo appears on the top left hand corner of your masthead</div>
            <bs-form-image-upload
              data-id=${fld('franchisee-positive-logo')}
              .value="${this.getAbsoluteVirtualFilePath(this.franchisee?.positiveLogoVirtualPath)}"
              data-label=${tlang`Colour Logo`}
            ></bs-form-image-upload>
          </div>
          <div class="col-sm-6 form-column">
            <h4 class="section-header branding-image-header">White Logo</h4>
            <div class="branding-image-description">This logo appears on printed reports ( PDF )</div>
            <bs-form-image-upload
              data-id=${fld('franchisee-negative-logo')}
              .value="${this.getAbsoluteVirtualFilePath(this.franchisee?.negativeLogoVirtualPath)}"
              data-label=${tlang`White Logo`}
            ></bs-form-image-upload>
          </div>
        </div>
        <h2>${tlang`Specifications`}:</h2>
        <p>${brandingMsg}</p>
      </form>
    </div>`;
  }
}
