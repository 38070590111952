import { customElement } from 'lit/decorators.js';
import {
  ProjectListView,
  ProjectListViewOptions,
  ProjectSummaryTable
} from '../../../projects/views/project-list-view';
import { SSEProject } from '../../../projects/data/sse-project';
import { notificationSignal } from '../../../components/ui/icons/icon-notification-signal';
import { defaultStagedThreshold, EventCancellation } from '../../../api/staged-event';
import { stagedExecution, WMEventSource } from '../../../api/event-source';
@customElement('wm-franchiseeprojectlistview')
export class FranchiseeProjectListView extends ProjectListView {
  constructor(options: ProjectListViewOptions) {
    super(options);
  }
  afterRefresh() {
    this._eventCancel.cancel();
  }
  async dispose(): Promise<void> {
    await super.dispose();
    this._eventCancel.cancel();
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _eventProjectUpdated = stagedExecution({
    threshold: defaultStagedThreshold,
    cancelToken: this._eventCancel,
    eventFinally: () => notificationSignal(false),
    event: async () => {
      this.tables.forEach(t => (t.dataIsStale = true));
      const table = this.pageControl.activePage?.data as ProjectSummaryTable;
      this.staleOrders = [];
      if (table) {
        await table.refreshData();
        table.dataIsStale = false;
        //  fireQuickInformationToast(tlang`Your Quotes have been refreshed`);
      }
      //reload the page.. we dont know what moved, or where, or what state its in
    },
    eventTriggered: (_data: SSEProject) => {
      notificationSignal(true);
      //fireQuickInformationToast(tlang`Updates are coming soon`);
    },
    eventActioned: (data: SSEProject) => {
      if (!this.staleOrders.includes(data.id)) this.staleOrders.push(data.id);
      const table = this.pageControl.activePage?.data as ProjectSummaryTable;
      table.invalidate();
      //fireQuickInformationToast(tlang`More Updates are coming, refreshing soon`);
    }
  });

  connectedCallback(): void {
    super.connectedCallback();
    WMEventSource.getInstance().addEventListener(WMEventSource.project, this._eventProjectUpdated);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    WMEventSource.getInstance().removeEventListener(WMEventSource.project, this._eventProjectUpdated);
  }
}
