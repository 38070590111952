import { customElement } from 'lit/decorators.js';
import { PurchaseOrderListView } from '../../../purchase-orders/views/purchase-order-list-view';
import {
  PurchaseOrderSummaryTable,
  PurchaseOrderSummaryTableOptions
} from '../../../purchase-orders/views/purchase-order-summary-table';
import { FranchiseePurchaseOrderSummaryTable } from './franchisee-purchase-order-summary-table';
import { defaultStagedThreshold, EventCancellation } from '../../../api/staged-event';
import { stagedExecution, WMEventSource } from '../../../api/event-source';
import { SSEOrder } from '../../../purchase-orders/data/sse-order';
import { notificationSignal } from '../../../components/ui/icons/icon-notification-signal';

@customElement('wm-franchiseepurchaseorderlistview')
export class FranchiseePurchaseOrderListView extends PurchaseOrderListView {
  //this can be overridden in subclass to replace the table with different column/view etc
  protected purchaseOrderSummaryTableFactory(options: PurchaseOrderSummaryTableOptions): PurchaseOrderSummaryTable {
    return new FranchiseePurchaseOrderSummaryTable(options);
  }

  afterRefresh() {
    this._eventCancel.cancel();
  }
  async dispose(): Promise<void> {
    await super.dispose();
    this._eventCancel.cancel();
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _eventOrderUpdated = stagedExecution({
    threshold: defaultStagedThreshold,
    cancelToken: this._eventCancel,
    eventFinally: () => notificationSignal(false),
    event: async () => {
      this.updatePageTotals();
      this.tables.forEach(t => (t.dataIsStale = true));
      const table = this.pageControl.activePage?.data as PurchaseOrderSummaryTable;
      this.staleOrders = [];
      if (table) {
        await table.refreshData();
        table.dataIsStale = false;
        //  fireQuickInformationToast(tlang`Your Quotes have been refreshed`);
      }
      //reload the page.. we dont know what moved, or where, or what state its in
    },
    eventTriggered: (_data: SSEOrder) => {
      notificationSignal(true);
      //fireQuickInformationToast(tlang`Updates are coming soon`);
    },
    eventActioned: (data: SSEOrder) => {
      if (!this.staleOrders.includes(data.id)) this.staleOrders.push(data.id);
      const table = this.pageControl.activePage?.data as PurchaseOrderSummaryTable;
      table.invalidate();
      //fireQuickInformationToast(tlang`More Updates are coming, refreshing soon`);
    }
  });

  connectedCallback(): void {
    super.connectedCallback();
    WMEventSource.getInstance().addEventListener(WMEventSource.order, this._eventOrderUpdated);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    WMEventSource.getInstance().removeEventListener(WMEventSource.order, this._eventOrderUpdated);
  }
}
