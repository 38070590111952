export class DoOnce {
  event: () => Promise<void>;
  private triggered = false;
  constructor(event: () => Promise<void>) {
    this.event = event;
  }
  async run() {
    if (!this.triggered) {
      this.triggered = true;
      await this.event();
    }
  }
  reset() {
    this.triggered = false;
  }
}


