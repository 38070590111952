import { html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { DateTime } from 'luxon';
import { Snippet } from '../../../components/ui/events';
import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef } from '@softtech/webmodule-components';
import { DashboardWidget } from './dashboard-widget';
import { PurchaseOrderBranchView } from '../../../api/dealer-api-interface-franchisee';
import { localDateTimeToServer, serverDateTimeToLocal } from '../../../components/datetime-converter';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher';
import { PurchaseOrderApi } from '../../../api/purchase-order-api';
import { getApiFactory } from '../../../api/api-injector';
import { emptyGuid } from '../../../api/guid';
import { monitor } from '../../../components/monitor';

@customElement('dashboard-branch-monthly-delivery-pipeline')
export class BranchMonthlyDeliveryPipeline extends DashboardWidget {
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();

  @property({ type: String, attribute: false }) branchId: string = emptyGuid;
  @property({ type: String, attribute: false }) assignedToUserId: string = emptyGuid;
  @property() cutoff?: Date;

  @query('#delivery-pipeline-po-widget')
  protected table!: WebModuleLitTable;
  @state()
  private data: PurchaseOrderBranchView[] = [];

  @monitor(['branchId', 'assignedToUserId'])
  refresh() {
    this.table.fetchEvent();
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%purchase-order-abrev%% No.`,
        fieldName: 'purchaseOrderNumber',
        classes: 'colpx-90 purchase-order-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;

          return this.getPurchaseOrderLink(
            rowItem,
            rowItem.purchaseOrderNumber == 0 ? 'N/A' : rowItem.purchaseOrderNumber.toString()
          );
        }
      },
      {
        title: tlang`%%purchase-order-abrev%% Title`,
        fieldName: 'title',
        classes: 'colpx-100 purchase-order-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;
          return this.getPurchaseOrderLink(rowItem, rowItem.title);
        }
      },
      {
        title: tlang`MFG Ref.`,
        fieldName: 'reference',
        classes: 'colpx-90 mfg_reference',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;
          return rowItem.reference == null ? '' : rowItem.reference;
        }
      },
      {
        title: tlang`MFG Status`,
        fieldName: 'supplierSystemStatus',
        classes: 'colpx-90 mfg_status',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;
          return rowItem.supplierSystemStatus == null ? '' : rowItem.supplierSystemStatus;
        }
      },
      {
        title: tlang`Proposed Delivery`,
        fieldName: 'installationDate',
        classes: 'colpx-90 purchase-order-installation-date',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;
          return rowItem.installationDate == null
            ? ''
            : serverDateTimeToLocal(rowItem.installationDate).toLocaleDateString();
        }
      },
      {
        title: tlang`MFG Delivery`,
        fieldName: 'supplierSystemDeliveryDate',
        classes: 'colpx-90 purchase-order-mfg-delivery-date',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const rowItem = item as PurchaseOrderBranchView;
          return rowItem.supplierSystemDeliveryDate == null
            ? ''
            : serverDateTimeToLocal(rowItem.supplierSystemDeliveryDate).toLocaleDateString();
        }
      }
    ];
  }

  protected getHeader(): Snippet {
    return tlang`Delivery Pipeline`;
  }

  protected isNearDeadlineOrOverdue(data: PurchaseOrderBranchView): boolean {
    const installDate = data.supplierSystemDeliveryDate ?? data.installationDate;
    const installationDate = installDate ? serverDateTimeToLocal(installDate) : null;

    return installationDate != null && installationDate < DateTime.now().plus({ days: 3 }).toJSDate();
  }

  protected getBody(): Snippet {
    const getRowClass = (item: PurchaseOrderBranchView) => {
      if (this.isNearDeadlineOrOverdue(item)) {
        return 'table-danger';
      }
      return '';
    };

    return html`
      <div class="delivery-pipeline-po-widget">
        <webmodule-lit-table
          id="delivery-pipeline-po-widget"
          .columns=${this.getColumns()}
          .data=${this.data}
          tablestyle="nestedtable"
          pageLength="15"
          .clickrows=${false}
          .itemRowClassEvent=${getRowClass}
          @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        >
        </webmodule-lit-table>
      </div>
    `;
  }

  protected getPurchaseOrderLink(row: PurchaseOrderBranchView, value: string) {
    return html`<a
      class="purchase-order-link"
      href="${resolveURL(resourcePurchaseOrder, row.id)}"
      data-purchaseorderid="${row.id}"
      >${this.htmlEncode(value)}</a
    >`;
  }

  private async internalDataLoad(e: CustomEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const index = (e.detail?.pageIndex as number) ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const length = (e.detail?.pageLength as number) ?? 16;

    const serverDate = this.cutoff == null ? null : localDateTimeToServer(this.cutoff);

    const results = await this.purchaseOrderApi.getPurchaseOrderDeliveryPipeline({
      purchaseOrderOwnerId: this.branchId ?? emptyGuid,
      assignedToUserId: this.assignedToUserId ?? emptyGuid,
      pageIndex: index,
      pageSize: length,
      cutOffDate: serverDate
    });

    if (results) {
      this.data = results.purchaseOrdersBranchView.results;
      this.table.rowCount = results.purchaseOrdersBranchView.count;
    }
  }
}
