function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

/**
 *will not run until it stops being called for the wait period.
 * @param func function to passthrough. should use a bound function with this already set
 * @param wait
 * @returns
 */
export function debounce(func, wait) {
  let timeout;
  let waitFunc;

  return (...args) => {
    if (isFunction(wait)) {
      waitFunc = wait;
    } else {
      waitFunc = function () {
        return wait;
      };
    }

    let later = function () {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}
/**
 * will run immediately and refuse more requests until a set time without new requests
 * @param func
 * @param wait
 * @returns
 */
export function runAndDebounce(func, wait) {
  let timeout;
  let waitFunc;
  let block = false;
  return (...args) => {
    if (isFunction(wait)) {
      waitFunc = wait;
    } else {
      waitFunc = function () {
        return wait;
      };
    }

    let later = function () {
      timeout = null;
    };
    if (!block) func(...args);
    block = true;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}

/**
 * returns an event that can only run one at a time, and will ignore extra calls during a current exectution
 * will return immediately after running, or immediately on starting async call, but will support blocking normal or async calls.
 * @param func
 * @returns
 */
export function block(func) {
  let block = false;
  return (...args) => {
    let unblock = true;
    if (!block)
      try {
        block = true;
        const possiblePromise = func(...args);
        if (possiblePromise?.then)
          possiblePromise.then(() => {
            block = false;
          });
      } finally {
        if (unblock) block = false;
      }
  };
}
/*
    will only allow a single execute at a time, and can be awaited on
*/
export function blockAsync(func) {
  let block = false;
  return async (...args) => {
    if (!block)
      try {
        block = true;
        await func(...args);
      } finally {
        block = false;
      }
  };
}
