import { getApiToken, tlang } from '@softtech/webmodule-components';
import { lapsedMilliseconds } from '../components/time';
import { defaultStagedThreshold, defaultTestInterval, IEventCancellation } from './staged-event';
import { fireQuickInformationToast } from '../toast-away';
import { html } from 'lit';
import { tlangMarkdown } from '../components/markdown';
import { debounce } from '../components/debounce';
import { emptyGuid } from './guid';
import { GlobalNotificationManager } from '../components/ui/icons/icon-notification-signal';

export type EventSourceHandler = (data: any, eventName: string) => void;
interface EventSourceBinder {
  eventName: string;
  event: EventSourceHandler;
}
export interface IStagedExecutionOptions {
  event: () => void | Promise<void>;
  threshold?: number;
  title?: string;
  eventTriggered?: EventSourceHandler;
  eventFinally?: () => void;
  eventActioned?: EventSourceHandler;
  cancelToken?: IEventCancellation;
  testInterval?: number;
}

//staged execution should only be used in cases where recieving a message is
//enough info and the data is not important
export function stagedExecution(options: IStagedExecutionOptions): EventSourceHandler {
  //create a closure that will execute on a timed interval
  const intervalms = options.testInterval ?? defaultTestInterval;
  let timer: NodeJS.Timeout | undefined = undefined;
  let triggered: Date | undefined = undefined;
  let actioned: Date | undefined = undefined;
  let actionCount = 0;
  let eventActionQueue: { data: any; eventName: string }[] = [];
  let executing = false;
  let restart = false;

  const reset = () => {
    clearInterval(timer);
    timer = undefined;
    triggered = undefined;
    actionCount = 0;
    actioned = undefined;
  };
  const intervalEvent = async () => {
    if (options.cancelToken?.cancelled) {
      reset();
      options.cancelToken?.reset();
      options.eventFinally?.();
      return;
    }
    const ms = lapsedMilliseconds(triggered!);
    if (ms >= (options.threshold ?? defaultStagedThreshold)) {
      try {
        executing = true;
        await options.event();
        options.eventFinally?.();
      } finally {
        executing = false;
        reset();
        if (restart) {
          try {
            options.eventTriggered?.(eventActionQueue[0].data, eventActionQueue[0].eventName);
            eventActionQueue
              .filter((_x, index) => index > 0)
              .forEach(x => {
                options.eventActioned?.(x.data, x.eventName);
              });
          } finally {
            eventActionQueue = [];
          }

          startProcess();
        }
      }
      return;
    }
    timer = setTimeout(intervalEvent, intervalms);
  };
  return (data: any, eventName: string) => {
    console.log(`staged ${eventName} with ${data}`);
    if (!triggered) {
      options.eventTriggered?.(data, eventName);
      options.eventActioned?.(data, eventName);
      startProcess();
    } else {
      if (executing) restart = true;
      actionCount++;
      actioned = new Date();
      if (executing) eventActionQueue.push({ data, eventName });
      else options.eventActioned?.(data, eventName);
      console.log(
        `Triggered Event '${options.title ?? 'no name'}' actioned ${actionCount} since ${triggered.toLocaleTimeString()}, at ${actioned.toLocaleTimeString()}`
      );
    }
  };

  function startProcess() {
    executing = false;
    restart = false;
    options.cancelToken?.reset();
    triggered = new Date();
    actionCount++;
    actioned = triggered;

    timer = setTimeout(intervalEvent, intervalms);
    console.log(`Triggered Event '${options.title ?? 'no name'}' started ${triggered.toLocaleTimeString()}`);
  }
}

export class WMEventSource {
  public static quote = 'quote';
  public static order = 'order';
  public static projectDocument = 'project-document';
  public static projectDocumentDel = 'project-document-delete';
  public static project = 'project';
  public static projectResource = 'project-resource-reference';
  public static projectResourceDel = 'project-resource-reference-delete';

  private static _instance: WMEventSource;
  userId: string = emptyGuid;
  public static getInstance(): WMEventSource {
    if (!WMEventSource._instance) {
      WMEventSource._instance = new WMEventSource();
    }
    return WMEventSource._instance;
  }
  private _source?: EventSource;
  private _events: { [key: string]: EventSourceBinder[] } = {};
  constructor() {
    this.addEventListener('ping', () => this._ping());
  }

  reconnectFrequencySeconds = 1;

  private _reconnect = debounce(
    (userId: string) => {
      this.connectUser(userId);
      // Double every attempt to avoid overwhelming server
      this.reconnectFrequencySeconds *= 2;
      // Max out at ~1 minute as a compromise between user experience and server load
      if (this.reconnectFrequencySeconds >= 64) {
        this.reconnectFrequencySeconds = 64;
      }
    },
    () => this.reconnectFrequencySeconds * 1000
  );

  connectUser(userId: string) {
    this.disconnect();
    const _token = getApiToken();
    const token = encodeURIComponent(_token);
    const sourceUrl = `${globalThis.dealerConfiguration.apiHost}/api/system/sse?userId=${userId}&token=${token}`;
    this._source = new EventSource(sourceUrl);
    this._source.onopen = () => {
      GlobalNotificationManager.getInstance().online = true;
      this.reconnectFrequencySeconds;
      console.log(`Event Source Connected:${sourceUrl}`);
      const dontShow = true; // localStorage.getItem(`source-event-notify:${userId}:v3`) === 'hide';
      if (dontShow) return;
      const clickEvent = () => {
        localStorage.setItem(`source-event-notify:${userId}:v3`, 'hide');
      };
      const infoTemplate = html`<div>
            ${tlangMarkdown`${'ref:eventsource-connected'}**You are now receiving
            live updates from server as they happen.**
            
            Your !!quote!! and !!project!! will refresh as data is updated on the server.
            `}
          <button class="btn btn-primary no-btn p-0 text-decoration-none fs-6" data-bs-dismiss="toast" @click=${clickEvent}>
            ${tlang`Don't show again`}
          </button>
        </div>`;

      fireQuickInformationToast(infoTemplate, 60000);
    };
    this._source.onmessage = event => {
      const data = JSON.parse(event.data);

      const eventName = data.eventName;
      this._events[eventName]?.forEach(eventBinder => {
        eventBinder.event(data, eventName);
      });
    };
    this._source.onerror = err => {
      GlobalNotificationManager.getInstance().online = false;
      this._source?.close();
      this._source = undefined;
      console.error('EventSource failed:', err);
      //TODO-SHOW AN INDICATOR GLOBALLY THAT WE ARE NOT RECEIVING MESSAGES
      this._reconnect(userId);
    };
  }

  private _ping = () => {
    console.log('SourceEvent Server Ping');
  };
  disconnect() {
    this._source?.close();
    this._source = undefined;
  }
  addEventListener(eventNames: string | string[], event: EventSourceHandler) {
    const events = Array.isArray(eventNames) ? (eventNames as string[]) : [eventNames];

    events.forEach(eventName => {
      const binder: EventSourceBinder = {
        eventName: eventName,
        event
      };
      let eventArray: EventSourceBinder[] = this._events[eventName];
      if (!eventArray) {
        eventArray = [];
        this._events[eventName] = eventArray;
      }
      eventArray.push(binder);
    });
  }
  removeEventListener(eventNames: string | string[], event: EventSourceHandler) {
    const events = Array.isArray(eventNames) ? (eventNames as string[]) : [eventNames];
    events.forEach(eventName => {
      const eventArray: EventSourceBinder[] = this._events[eventName];
      this._events[eventName] = eventArray?.filter(
        binder => !(binder.event === event && binder.eventName === eventName)
      );
    });
  }
}
