/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import {
  FrameAttribute,
  FrameAttributeGroupType,
  FrameValidationType,
  ModalPromise,
  QCQuoteValues,
  QuoteFrameBuyInItem
} from '@softtech/webmodule-data-contracts';
import { getApiFactory } from '../../../api/api-injector';
import { BlobApi } from '../../../api/blob-api';
import {
  BranchQuote,
  BranchQuoteSupport,
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  InputLineItem,
  LookupStockItem,
  PurchaseOrderState,
  QuoteItemConversationType,
  ResultGetBranchQuoteSupport
} from '../../../api/dealer-api-interface-franchisee';
import { ProjectState, ResourceType } from '../../../api/dealer-api-interface-project';
import {
  Address,
  InputQuoteState,
  Quote,
  QuoteAlternativeType,
  QuoteItem,
  QuotePresentation,
  QuotePrice,
  QuoteSetSibling,
  QuoteState,
  QuoteStateChangeReason
} from '../../../api/dealer-api-interface-quote';
import { FranchiseeApi } from '../../../api/franchisee-api';
import { emptyGuid, newGuid } from '../../../api/guid';
import { QuoteApi } from '../../../api/quote-api';
import { toJsonStr } from '../../../blob/converters';
import { clone, compare } from '../../../components/clone';
import { money } from '../../../components/currency-formatter';
import {
  localDateTimeToServer,
  localDateToServer,
  serverDateTimeToLocalDateTime,
  today
} from '../../../components/datetime-converter';
import {
  concatNotes,
  firstValidString,
  flagInSet,
  isEmptyOrSpace,
  joinWithCommaAnd
} from '../../../components/ui/helper-functions';
import { DevelopmentError } from '../../../development-error';
import { NullPromise } from '../../../null-promise';
import { getPurchaseOrderNumberFormatted } from '../../../purchase-orders/data/purchase-order-helper-functions';
import { QuoteContainer, QuoteContainerManager, StockLookupViewExtra } from '../../../quotes/data/quote-container';
import {
  canAppearOnSupplierOrder,
  getQuoteNumberFormatted,
  isFrame,
  isSpecialItem,
  mustAppearOnPurchaseOrder
} from '../../../quotes/data/quote-helper-functions';
import {
  getV6FrameForContainer,
  isQuoteFrameVersionCurrent,
  isSupplierUnitsMetric,
  isSupplierUsingSSI,
  isV6
} from '../../../quotes/data/v6/helper-functions';
import { fireQuickErrorToast, fireQuickInformationToast, fireQuickSuccessToast } from '../../../toast-away';
import { cache } from '../../cache/cache-registry';
import { userDataStore } from '../../common/current-user-data-store';
import {
  determineProjectShippingAddress,
  getProjectNumberFormatted
} from '../../projects/data/project-helper-functions';
import { FranchiseeQuoteContainer } from './franchisee-quote-container';
import { getQuoteStateEngine } from './quote-state-engine';

import { DateTime } from 'luxon';
import { information } from '../../../components/ui/modal-option';
import { QuoteItemContainer } from '../../../quotes/data/quote-item-container';

import { currentUserClaims } from '../../../components/currentuser-claims';
import { SaveWorkflowModal } from '../../../components/save-workflow';
import { InformationDispatcher } from '../../../components/ui/information-dispatcher';
import { getConfirmationReasonFor } from '../../../components/ui/modal-confirmation-reason';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { goURL } from '../../../components/ui/resource-resolver';
import { V6SSIProcessor } from '../../../quotes/data/v6/v6-ssi-processor';
import { V6SupplierQuoteBase } from '../../../quotes/data/v6/v6-supplier-quote';
import { resourceQuote } from '../../../quotes/ui/launcher';
import { getQuoteStateChangeReasonsForState } from '../../cache/quote-state-change-reason-cache';
import { highestValidationRank, rankToValidationType } from '../../../v6config/validation-rank';
import { SupplierApi } from '../../../api/supplier-api';
import { getQuoteSupplierDisplayName } from '../../../quotes/data/quoteSupplierProvider';
import { getProjectContainerManager } from '../../projects/project-ui-container-manager';
import { FranchiseeQuoteCreateProjectModal } from '../views/franchisee-quote-create-project-modal';
import { IUserQuoteStateEngine } from '../../../quotes/data/quote-state-engine';
import { autoSaveNotifier } from '../../../components/context/autoSaveNotifier';
import { V6FranchiseeQuoteProviderData } from './franchisee-quote-provider-data';
import { SSIProcessor } from '../../../quotes/data/ssi-processor';
import { v6SupportsVersion, v6Util, v6VersionMap } from '../../../v6config/v6config';

export class FranchiseeQuoteContainerManager extends QuoteContainerManager {
  protected userClaims = currentUserClaims();
  franchiseeApi: FranchiseeApi;
  supplierApi: SupplierApi = getApiFactory().supplier();
  private informationDispatcher = new InformationDispatcher();
  private saveModal?: SaveWorkflowModal;
  private modalState?: ModalPromise;
  private _supplierName?: string;

  constructor(original: QuoteContainer, quoteApi: QuoteApi, blobApi: BlobApi, franchiseeApi: FranchiseeApi) {
    super(original, quoteApi, blobApi);
    if (!(original instanceof FranchiseeQuoteContainer))
      throw new DevelopmentError('FranchiseeQuoteContainerManager only supports FranchiseeQuoteContainer');
    this.franchiseeApi = franchiseeApi;
  }

  get franchiseeContainer(): FranchiseeQuoteContainer {
    return this.container as FranchiseeQuoteContainer;
  }

  get franchiseeBackup(): FranchiseeQuoteContainer {
    return this.backup as FranchiseeQuoteContainer;
  }
  get hasQuoteCalculationData(): boolean {
    const data = this.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.quoteCalculations !== undefined;
  }
  get branchQuote(): BranchQuote {
    if (!this.franchiseeContainer.branchQuote) {
      throw new DevelopmentError('BranchQuote is null'); //no waiting
    }
    return this.franchiseeContainer.branchQuote;
  }

  get supplierName(): string {
    return this._supplierName ?? '';
  }

  public get isAnyStateChangeAllowed(): boolean {
    const stateEngine = this.getStateEngine();
    return !this.isLockedFromUse() && stateEngine.isAnyStateChangeAllowed();
  }

  public async currentUserMustValidate(): Promise<boolean> {
    return (
      !this.isLockedFromUse() &&
      (this.currentStateRequiresValidation || this.isAnyStateChangeAllowedThatRequiresValidation) &&
      (await this.anyFrameOutOfDate([]))
    );
  }
  public get currentStateRequiresValidation(): boolean {
    switch (this.quoteState) {
      case QuoteState.Approved:
      case QuoteState.Lapsed:
      case QuoteState.Cancelled:
      case QuoteState.Rejected:
      case QuoteState.Accepted_RefusedBySupplier:
        return false;
      default:
        return true;
    }
  }
  public get isAnyStateChangeAllowedThatRequiresValidation(): boolean {
    const stateEngine = this.getStateEngine();
    return (
      !this.isLockedFromUse() &&
      stateEngine.isAnyStateChangeAllowed([
        QuoteState.Active,
        QuoteState.IssuePending,
        QuoteState.Issued,
        QuoteState.Accepted,
        QuoteState.SupplierReviewPending,
        QuoteState.SupplierReviewed,
        QuoteState.Approved,
        QuoteState.Accepted_AssignedToReviewer
      ])
    );
  }

  public get isValidationOnLockedQuote(): boolean {
    const stateEngine = this.getStateEngine();
    return (
      this.isReadonly() &&
      !this.isLockedFromUse() &&
      (stateEngine.isStateChangeAllowed(QuoteState.Accepted) ||
        stateEngine.isStateChangeAllowed(QuoteState.Approved) ||
        stateEngine.isStateChangeAllowed(QuoteState.SupplierReviewPending))
    );
  }

  getStateEngine(): IUserQuoteStateEngine {
    return getQuoteStateEngine(this);
  }
  async fetchCache() {
    await Promise.allSettled([
      cache().quote.preFetch([this.quoteId]),
      cache().projectResourceLink.preFetch([this.quoteId])
    ]);
  }

  async createBranchQuote() {
    await userDataStore.loadCoreDetails();
    if (!userDataStore.defaultBranch) throw new Error(tlang`Current User does not have a %%branch%%.`);
    const branchQuote: BranchQuote = {
      branchId: userDataStore.defaultBranch.id,
      globalSupplierSupplierId: this.quote.supplierId,
      quoteSetId: this.quote.quoteSetId,
      //Use the exact same id for the branch quote. makes it easy to extend
      id: this.quote.id,
      clientId: emptyGuid,
      clientName: '',
      clientTypeId: emptyGuid,
      contactId: emptyGuid,
      clientTypeName: '',
      contactName: '',
      dateCreated: localDateTimeToServer(new Date()),
      recordVersion: ''
    };

    //if there is any reason we can't create this, we won't worry about it now, if we already have the quote
    //created. we will just attempt to create it later. if it cant be done later, it is probably
    //a system failure.
    const branchQuoteResult = await this.franchiseeApi.createBranchQuote({
      branchQuote: branchQuote
    });
    if (!branchQuoteResult) return null;
    return branchQuoteResult.branchQuote;
  }

  private _branchQuoteSupport?: ResultGetBranchQuoteSupport | null;
  async needsQuoteSupport(force?: boolean): NullPromise<ResultGetBranchQuoteSupport> {
    await this.needsQuote();
    if (!this._branchQuoteSupport || force)
      this._branchQuoteSupport = await getApiFactory().franchisee().getBranchQuoteSupport({
        quoteSetId: this.quote.quoteSetId,
        branchQuoteSupportId: null,
        quoteId: this.quote.id
      });
    return this._branchQuoteSupport;
  }
  public get branchQuoteSupport(): ResultGetBranchQuoteSupport {
    return this._branchQuoteSupport ?? { conversationLinks: [], items: [], masterDocuments: [] };
  }
  async needsQuote(): Promise<boolean> {
    if (!this.container.quote) {
      const result = await this.api.getQuote({ quoteId: this.quoteId });
      let branchQuote =
        (
          await this.franchiseeApi.getBranchQuotes({
            branchQuoteIds: [this.quoteId]
          })
        )?.branchQuotes[0] ?? null;
      //we will build a new branch quote on the fly.
      if (!branchQuote) branchQuote = await this.createBranchQuote();

      if (result && branchQuote) {
        this._supplierName = await getQuoteSupplierDisplayName(result.quote.supplierId);
        await this.resetBranchQuote(
          result.quote,
          result.quotePrice,
          result.quotePresentation,
          branchQuote,
          result.siblings
        );
        await this.fetchCache();
      } else return false;
    }
    return true;
  }

  protected async createProject() {
    //When quote is changed to Active, we need to generate a quote number;
    this.generateQuoteNumberOnSave();

    await userDataStore.loadCoreDetails();
    const resultClient = await getApiFactory().client().getClient({
      clientId: this.branchQuote.clientId
    });
    let clientAddress: Address | undefined = undefined;
    if (resultClient?.client) clientAddress = resultClient.client.physicalAddress;

    //use given quote address if it exists, otherwise client, or empty address if neither of the two exist
    const addressToUse = determineProjectShippingAddress(
      this.quote.shippingAddress,
      true,
      clientAddress,
      resultClient?.client.shipToPhysicalAddress
    );
    const projectApi = getApiFactory().project();

    const projectTitleModal = new FranchiseeQuoteCreateProjectModal();
    await projectTitleModal.showModal();
    const projectTitle = projectTitleModal.ok ? projectTitleModal.projectTitle : this.quote.title;

    const project = await projectApi.createProject({
      project: {
        state: ProjectState.Active,
        title: projectTitle,
        number: 0,
        budget: this.quote.budget ?? 0,
        id: newGuid(),
        clientId: this.branchQuote.clientId,
        contactId: this.branchQuote.contactId,
        clientTypeId: this.branchQuote.clientTypeId,
        description: this.quote.description,
        recordVersion: '',
        dateCreated: localDateTimeToServer(today()),
        lastModifiedDate: localDateTimeToServer(today()),
        shippingNotes: null,
        defaultAddress: addressToUse,
        shipToDefaultAddress: false,
        projectOwnerId: this.quote.quoteOwnerId,
        creationUserId: emptyGuid, //set on server
        lastModifiedUserId: emptyGuid, //set on server
        assignedToUserId: emptyGuid //set on server
      },
      numberSeed: this.quote.quoteOwnerId,
      projectReferences: [
        {
          resourceId: this.quote.id,
          typeOf: ResourceType.Quote,
          parentResourceId: emptyGuid,
          parentTypeOf: ResourceType.None
        }
      ]
    });

    if (project) {
      await cache().project.updateLocal(project.project.id);

      fireQuickSuccessToast(`Project ${getProjectNumberFormatted(project.project)} created!`, 1000);
      return true;
    }

    return true;
  }
  async shouldCreatePurchaseOrder() {
    const items = await this.itemsForPurchaseOrder();
    // if we end up with free hand items and/or shipping only, we should not create a purchase order
    const shouldCreatePO = items.some(item => mustAppearOnPurchaseOrder(item));
    return shouldCreatePO;
  }
  async itemsForPurchaseOrder() {
    await this.needsQuoteItems(true);
    return this.container.items?.filter(item => canAppearOnSupplierOrder(item)) ?? [];
  }
  async createPurchaseOrder() {
    const projectApi = getApiFactory().project();
    const purchaseOrderApi = getApiFactory().purchaseOrder();
    const prlCache = cache().projectResourceLink;
    const purchaseOrderCache = cache().purchaseOrder;
    const priceForItem = (item: QuoteItem) => this.container.itemPrices?.find(x => x.id === item.id);

    if (!(await this.shouldCreatePurchaseOrder())) return true;

    const items = await this.itemsForPurchaseOrder();

    const quoteProjectResourceLink = await prlCache.getData(this.quoteId);
    if (!quoteProjectResourceLink)
      throw new DevelopmentError(`Quote: ${this.quote.id} missing project resource link in cache`);
    //Maybe we can use the project cache here?
    const projectResult = await projectApi.getProject({
      projectId: quoteProjectResourceLink?.projectId ?? emptyGuid
    });

    //We should not get an empty project since the project would have been created earlier in the workflow (when
    //a quote is issued)
    if (projectResult == null) throw new DevelopmentError(`Invalid State. Quote ${this.quoteId} missing Project`);

    // we have at least one special item, so proceed with the PO creation
    //convert quote items to references
    const lineItems: InputLineItem[] =
      items.map(item => {
        const price = priceForItem(item);
        return {
          id: item.id,
          grossQuantityCost: price?.supplierNettQuantityCost ?? 0,
          grossSupplierQuantityCost: price?.supplierGrossQuantityCost ?? 0,
          supplierPriceAdjustment: price?.supplierPriceAdjustment ?? 0
        };
      }) ?? [];

    const description = concatNotes(projectResult.project.description, this.quote.description);
    const isSupplierIssuingOrder =
      currentUserClaims().isAgent && this.quote.supplierAuthorizedToIssueOrderOnQuoteApproval;

    const purchaseOrderResult = await purchaseOrderApi.createPurchaseOrder({
      numberSeed: this.quote.quoteOwnerId,
      purchaseOrder: {
        state: isSupplierIssuingOrder ? PurchaseOrderState.IssuedPending : PurchaseOrderState.Draft,
        purchaseOrderNumber: 0,
        id: newGuid(),
        branchQuoteId: this.branchQuote.id,
        creationUserId: emptyGuid,
        dateCreated: localDateTimeToServer(today()),
        calculatedAdjustmentTotal: 0,
        calculatedNetTotal: 0,
        installationDate: this.quote.installationDate,
        supplierSystemLastModifiedDate: null,
        supplierSystemClosedDate: null,
        supplierSystemDeliveryDate: null,
        supplierSystemShippingLeadTime: null,
        supplierSystemStatus: null,
        recordVersion: '',
        description: description,
        title: `${this.quoteTitle}`,
        reference: '',
        customPurchaseOrderNumber: this.quote.customQuoteNumber,
        supplierId: this.quote.supplierId,
        lastModifiedDate: null,
        lastModifiedUserId: emptyGuid,
        assignedToUserId: this.quote.assignedToUserId
      },
      lineItems: lineItems
    });

    if (!purchaseOrderResult) {
      fireQuickErrorToast(tlang`Unable to create %%purchase-order%%.`);
      return false;
    } else {
      if (purchaseOrderResult.purchaseOrder.state === PurchaseOrderState.IssuedPending) {
        const success = await V6SupplierQuoteBase.issuePurchaseOrder(
          this.branchQuote.branchId,
          await this.quote.id,
          projectResult.project.id,
          purchaseOrderResult.purchaseOrder.id
        );
        if (!success) {
          purchaseOrderResult.purchaseOrder.state = PurchaseOrderState.Draft;
          let bFailed = false;
          try {
            await purchaseOrderApi.updatePurchaseOrder({
              purchaseOrder: purchaseOrderResult.purchaseOrder,
              stateChangeReason: null
            });
          } catch {
            bFailed = true;
          }
          const msg = bFailed
            ? tlang`${'ref:issue-order-by-supplier-failed'}
                    An error occured while attempting to !!purchase-order-state-issued!! the %%purchase-order%%
                    
                    Reverting to %%purchase-order-state-draft%% failed, please contact support to help rectify this.

                    Support will manually need to fix the state on this order to %%purchase-order-state-draft%%

                    please note the number "Tenant-${
                      getCurrentUser()?.tenantId
                    }:PO-${purchaseOrderResult.purchaseOrder.id}"

                    `
            : tlang`${'ref:issue-order-by-supplier-failed-recovered'}
                    An error occured while attempting to !!purchase-order-state-issued!! the %%purchase-order%%
                    
                    %%purchaseorder%% has been set to %%purchaseorder-state-draft%% and will need to be processed later.

                    `;
          await information(msg, tlang`Error processing %%purchase-order%%`);
        }
      }

      await purchaseOrderCache.updateLocal(purchaseOrderResult.purchaseOrder.id);
      fireQuickSuccessToast(
        tlang`%%purchase-order%% ${getPurchaseOrderNumberFormatted(purchaseOrderResult.purchaseOrder)} created!`,
        1000
      );
      await projectApi.createProjectResourceReference({
        resourceId: purchaseOrderResult.purchaseOrder.id,
        projectId: projectResult.project.id,
        typeOf: ResourceType.PurchaseOrder,
        parentResourceId: this.quote.id,
        parentTypeOf: ResourceType.Quote
      });
      return true;
    }
  }

  public async purchaseOrderId(): Promise<string> {
    if (!this.quote) return emptyGuid;
    const prlCache = cache().projectResourceLink;
    const data = await prlCache.getData(this.quote.id);
    return data?.purchaseOrderId ?? emptyGuid;
  }
  public async getInstallationAddress(): Promise<Address | null> {
    const id = await this.projectId();
    if (isEmptyOrSpace(id)) return null;
    const proj = await cache().project.getData(id);
    return proj?.projectSummary.installationAddress ?? null;
  }

  public async projectId(): Promise<string> {
    if (!this.quote) return emptyGuid;
    const prlCache = cache().projectResourceLink;
    const data = await prlCache.getData(this.quote.id);
    return data?.projectId ?? emptyGuid;
  }

  public isBuyInPriceCurrent(qic: QuoteItemContainer): boolean {
    if (this.priceValidation && this.priceValidation.length != 0) {
      return this.priceValidation.findIndex(x => x.id == qic.item.id) < 0;
    }

    return true;
  }

  isFrameOutOfDate(quoteItemContainer: QuoteItemContainer, errors: string[], includeBuyIn = true): boolean {
    if (!(isV6(quoteItemContainer) && isFrame(quoteItemContainer.item))) return false;
    let outOfDate = false;

    if (!isQuoteFrameVersionCurrent(quoteItemContainer)) {
      errors.push(
        tlang`${'ref:frame-outofdate-msg:version'}%%frame%% Position ${this.itemPosition(
          quoteItemContainer.item.id
        )} needs to be updated because of %%supplier%% changes`
      );
      outOfDate = true;
    }
    if (!this.isBuyInPriceCurrent(quoteItemContainer) && includeBuyIn) {
      errors.push(
        tlang`${'ref:frame-outofdate-msg:buyin'}%%frame%% Position ${this.itemPosition(
          quoteItemContainer.item.id
        )} needs to be updated because of %%stock%% changes`
      );
      outOfDate = true;
    }
    if (this.quoteAttributesMismatch(quoteItemContainer)) {
      errors.push(
        tlang`${'ref:frame-outofdate-msg:quoteattributes'}%%frame%% Position ${this.itemPosition(
          quoteItemContainer.item.id
        )} needs to be updated because of %%quote%% attribute changes`
      );
      outOfDate = true;
    }
    return outOfDate;
  }
  quoteAttributesMismatch(quoteItemContainer: QuoteItemContainer): boolean {
    if (!v6SupportsVersion(v6VersionMap.hasQuoteDefaultConversion)) return false;
    function attributeIsDifferent(attr1: FrameAttribute, attr2?: FrameAttribute): boolean {
      if (!attr2) return true;
      if (attr2.value !== attr1.value) return true;

      return false;
    }
    const data = this.quoteProviderData() as V6FranchiseeQuoteProviderData;
    const f = getV6FrameForContainer(quoteItemContainer);
    const quotedefaults = v6Util().getQuoteDefaults(data.defaultOptions as object);
    if (!f || !quotedefaults) return false;
    const quoteItemStuff = f.attributeGroups.find(x => x.groupType === FrameAttributeGroupType.Quote);
    const quoteStuff = quotedefaults.find(group => group.groupType === FrameAttributeGroupType.Quote);
    //no attributes in either
    if (!quoteItemStuff && !quoteStuff) return false;
    //one side has attributes and the other does not, must validate
    if (!quoteItemStuff || !quoteStuff) return true;

    //both sides have attributes, do a compare

    //check all items in quote to see if they exist and match in quoteItem
    if (
      quoteStuff.attributes.some(attr =>
        attributeIsDifferent(
          attr,
          quoteItemStuff.attributes.find(attr1 => attr1.code === attr.code)
        )
      )
    )
      return true;

    //check all items in quoteitem to see if they exist and match in quote
    if (
      quoteItemStuff.attributes.some(attr =>
        attributeIsDifferent(
          attr,
          quoteStuff.attributes.find(attr1 => attr1.code === attr.code)
        )
      )
    )
      return true;

    return false;
  }

  async anyFrameOutOfDate(errors: string[]): Promise<boolean> {
    await this.needsQuoteItems();
    const items = this.container.items ?? [];
    let outOfDate = false;

    //no implementation yet for non-v6
    if (!this.isV6) return false;

    for (let i = 0; i < items.length; i++) {
      const quoteItemContainer = this.quoteItemContainer(items[i].id);
      outOfDate = outOfDate || this.isFrameOutOfDate(quoteItemContainer, errors);
    }
    return outOfDate;
  }

  async quoteFramesAreReadyForIssuing(errors: string[]): Promise<boolean> {
    await this.needsQuoteItems();
    const items = this.container.items ?? [];
    let success = true;

    if (items.length == 0) {
      errors.push(tlang`%%quote%% doesn't contain any line items.`);
      return false;
    }

    //no implementation yet for non-v6
    if (!this.isV6) return false;

    //removed health check code. the backend can revert the issue pending if it cannot complete the task

    for (let i = 0; i < items.length; i++) {
      const quoteItemContainer = this.quoteItemContainer(items[i].id);
      if (!(isV6(quoteItemContainer) && isFrame(quoteItemContainer.item))) continue;

      if (isFrame(quoteItemContainer.item)) {
        if (this.frameHasCriticalWarnings(quoteItemContainer)) {
          errors.push(
            tlang`${'ref:frame-warnings-msg'}%%frame%% Position ${this.itemPosition(
              quoteItemContainer.item.id
            )} has Warnings/Errors that must be addressed`
          );
          success = false;
        }
      }
    }
    success = success && (await this.anyFrameOutOfDate(errors));
    return success;
  }
  frameHasCriticalWarnings(item: QuoteItemContainer) {
    const isQuoteItemBuyInPricesCurrent = (quoteItemContainer: QuoteItemContainer) => {
      if (this.priceValidation) return this.priceValidation?.findIndex(x => x.id == quoteItemContainer.item.id) < 0;
      else return true;
    };

    const getItemValidationType = (quoteItemContainer: QuoteItemContainer): FrameValidationType => {
      //we are currently only supporting items that are for v6
      if (!isV6(quoteItemContainer)) return FrameValidationType.nothing;

      const data = getV6FrameForContainer(quoteItemContainer);
      if (!data) return FrameValidationType.nothing;

      if (this.currentStateRequiresValidation) {
        if (!isQuoteFrameVersionCurrent(quoteItemContainer)) return FrameValidationType.outOfDate;

        if (!this.isBuyInDataLocked && !isQuoteItemBuyInPricesCurrent(quoteItemContainer)) {
          return FrameValidationType.outOfDate;
        }
      }
      return rankToValidationType(highestValidationRank(data.validations));
    };
    const validation = getItemValidationType(item);

    return validation == FrameValidationType.critical || validation == FrameValidationType.outOfDate;
  }

  protected async sendIssueQuote(): Promise<boolean> {
    if (this.isV6) {
      const projectId = await this.projectId();
      if (await V6SupplierQuoteBase.issueQuote(this.branchQuote?.branchId ?? emptyGuid, this.quote.id, projectId)) {
        fireQuickInformationToast(tlang`You will be emailed a link to the document when it is ready`);
      } else return false;
    }
    return true;
  }

  protected async calculateValidUntilDate(): Promise<boolean> {
    let calculated = false;

    const qc = await this.supplierApi.getSupplierQuoteConfig({
      supplierId: this.quote.supplierId
    });

    if (qc != null) {
      this.quote.validUntilDate = localDateToServer(today(qc.validityPeriod));
      calculated = true;
    }

    return calculated;
  }

  public async canIssueQuote(): Promise<boolean> {
    const errors: string[] = [];

    if (!this.quote.validUntilDate) {
      const validUntilCalculated = await this.calculateValidUntilDate();

      if (validUntilCalculated == null) {
        errors.push(tlang`Unable to issue %%quote%% because the setting for "Valid for (Days):" cannot be resolved.`);
      }
    }

    const projectId = await this.projectId();
    const projectManager = getProjectContainerManager(projectId);
    await projectManager.needsProject();
    const project = projectManager.project;

    if (!project) {
      //Don't kill the quote with an error
      errors.push(tlang`Cannot issue a %%quote%% without being part of a valid %%project%%. Please contact your support if this
                seems incorrect`);
    } else {
      const physicalLine1 = project.defaultAddress.line1;
      const physicalPostCode = project.defaultAddress.postcode;
      if (isEmptyOrSpace(physicalLine1)) errors.push(tlang`%%project%% does not have a Street Address`);
      if (isEmptyOrSpace(physicalPostCode)) errors.push(tlang`%%project%% does not have a Zip Code`);
    }

    const quoteShippingLine1 = this.quote.shippingAddress?.line1;
    const quoteShippingPostCode = this.quote.shippingAddress?.postcode;

    if (isEmptyOrSpace(this.quote.title)) errors.push(tlang`%%quote%% does not have a title`);

    //Getting here means we already checked if the valid until
    const validDate = serverDateTimeToLocalDateTime(this.quote.validUntilDate ?? 'invalid');
    if (!this.quote.validUntilDate || (this.quote.validUntilDate && validDate.isValid && validDate < DateTime.now())) {
      errors.push(tlang`'Valid to' Date "${validDate.toLocaleString()}" must be a date in the future not in the past.`);
    }

    if (isEmptyOrSpace(quoteShippingLine1))
      errors.push(tlang`%%quote%% shipping address does not have a Street Address`);

    if (isEmptyOrSpace(quoteShippingPostCode)) errors.push(tlang`%%quote%% shipping address does not have a Zip Code`);

    await this.quoteFramesAreReadyForIssuing(errors);

    if (this.container.items) {
      for (const item of this.container.items) {
        if (isSpecialItem(item)) {
          const issuesStrings: string[] = [];
          const itemPrice = this.container.itemPrices?.find(x => x.id == item.id);

          if (isEmptyOrSpace(item.description)) issuesStrings.push(tlang`Supplier Reference`);

          if (itemPrice === undefined || itemPrice.singleUnitCost < 0) issuesStrings.push(tlang`valid Price`);

          if (issuesStrings.length > 0) {
            errors.push(
              tlang`%%special-item%% must have a ${joinWithCommaAnd(issuesStrings)} before a Quote can be Issued`
            );
          }
        }
      }
    }

    const supportTickets = await this.franchiseeApi.getBranchQuoteSupport({
      quoteSetId: this.quote.quoteSetId,
      branchQuoteSupportId: null,

      quoteId: this.quote.id
    });

    if (supportTickets) {
      for (const sr of supportTickets.items.filter(
        x =>
          x.type !== BranchQuoteSupportItemType.QuoteReview &&
          x.status != BranchQuoteSupportStatus.Cancelled &&
          x.status != BranchQuoteSupportStatus.Resolved
      )) {
        const itemType =
          sr.type === BranchQuoteSupportItemType.EngineeredToOrder
            ? tlang`%%special-item%%`
            : tlang`%%support-request%%`;
        errors.push(tlang`%%supplier%% ${itemType} "${sr.subject}" should be set to Resolved or Deleted`);
      }
    }

    if (errors.length != 0) {
      //changed the logic here to remove the abandon and close option.
      //they can do that themselves after completing this workflow
      await showValidations(errors, () => tlang`Unable to issue %%quote%%, correct the following and try again.`);
      return false;
    }

    return true;
  }

  async getQuoteReasons(quoteState: QuoteState): NullPromise<QuoteStateChangeReason[]> {
    const franchiseeId = userDataStore.franchisee.id;
    const stateReasons = await getQuoteStateChangeReasonsForState(quoteState, franchiseeId);
    if (stateReasons) {
      const displayOrder = stateReasons.presentation.itemDisplayOrder;
      const sortedItems: QuoteStateChangeReason[] = [];
      displayOrder.forEach(elementId => {
        const item = stateReasons.stateChangeReasons.find(item => item.id === elementId);
        if (item) {
          sortedItems.push(item);
        }
      });
      return sortedItems;
    }
    return null;
  }

  protected async canRejectOrCancel(state: QuoteState) {
    /**
     * Attempts to collect a user provided reason for changing the quotes state.
     * If there are no available reasons for a given state change we will skip asking for the reason.
     * If there are reasons available, but the user declines to given one, it will abort the state change.
     * @param quoteState The auote state we are trying to move to.
     * @param modalTitle The title of the reason modal.
     * @param modalMessage
     * @param confirmButtonText
     * @returns True if a reason was provided, or if no reasons are available. False if reasons are available but no reason whas selected.
     */
    const tryGetUserProvidedStateChangeReason = async (
      quoteState: QuoteState,
      modalTitle: string,
      modalMessage: string,
      reasonSelectLabel: string,
      confirmButtonText: string
    ): Promise<boolean> => {
      const stateReasons = await this.getQuoteReasons(quoteState);
      if (stateReasons) {
        const reasonResult = await getConfirmationReasonFor(
          stateReasons,
          modalTitle,
          modalMessage,
          reasonSelectLabel,
          confirmButtonText
        );
        if (reasonResult.result && reasonResult.reason) {
          const selectedReason = reasonResult.reason as QuoteStateChangeReason;
          this.stateChangeReason = {
            comment: reasonResult.comment ?? '',
            stateChangeReason: selectedReason
          };
          return true;
        }
        return false;
      }
      return true;
    };

    const status = state == QuoteState.Cancelled ? 'Cancel' : 'Reject';
    const statusAction = state == QuoteState.Cancelled ? 'cancelling' : 'rejecting';
    const statusLabel = state == QuoteState.Cancelled ? 'Cancellation' : 'Rejection';
    const quoteStatusText = tlang`${status} %%quote%%`;
    const modalMessage = tlang`Please select the reason for ${statusAction} the %%quote%%`;
    const selectReasonLabel = tlang`${statusLabel} Reason`;

    const hasReason = await tryGetUserProvidedStateChangeReason(
      state,
      quoteStatusText,
      modalMessage,
      selectReasonLabel,
      quoteStatusText
    );
    return hasReason;
  }

  protected async quoteStateBeforeChange(state: QuoteState, original: QuoteState): Promise<boolean> {
    //At this point in time, the quote state has not been edited.
    const stateHandler = getQuoteStateEngine(this);
    if (stateHandler.isStateChangeBlocked(state)) return false;

    switch (state) {
      case QuoteState.Active:
        if (original == QuoteState.Draft) {
          if (!(await this.isQuoteValid(QuoteState.Active))) return false;
          if (!(await this.createProject())) return false;
        }
        break;
      case QuoteState.Approved:
        //TODO-new state flow will add that we can create a purchase order that is not in draft state.
        //but already accepted. need to control that flow
        if (!(await this.createPurchaseOrder())) return false;
        break;
      case QuoteState.IssuePending:
        if (!(await this.canIssueQuote())) return false;
        break;
      case QuoteState.Cancelled:
      case QuoteState.Rejected:
        if (!(await this.canRejectOrCancel(state))) return false;
        break;
      case QuoteState.SupplierReviewed:
        if (!(await this.calculateValidUntilDate())) return false;
        break;
      default:
        return true;
    }

    return true;
  }
  protected async doAfterDeleteQuoteItem(quoteItemContainer: QuoteItemContainer) {
    if (isSpecialItem(quoteItemContainer.item)) {
      await getApiFactory()
        .franchisee()
        .updateBranchQuoteSupport({
          branchQuoteId: this.quoteId,
          quoteItemIdToDetach: quoteItemContainer.item.id,
          masterDocumentConversationId: null,
          branchQuoteSupportId: quoteItemContainer.item.id,
          status: BranchQuoteSupportStatus.Cancelled,
          subject: null,
          conversationEntries: [
            { id: newGuid(), attachments: [], users: [], text: tlang`Cancelled due to %%special-item%% deletion` }
          ]
        });
    }
  }
  protected async quoteStateAfterChange(_state: QuoteState, _original: QuoteState): Promise<boolean> {
    const updateQuoteReviewTicket = async (newStatus: BranchQuoteSupportStatus, branchQuoteId?: string) => {
      const supportReview = await this.getSupportReview();

      if (supportReview) {
        await this.franchiseeApi.updateBranchQuoteSupport({
          branchQuoteSupportId: supportReview.id,
          quoteItemIdToDetach: null,
          masterDocumentConversationId: null,
          status: newStatus,
          branchQuoteId: branchQuoteId ?? null,
          subject: null,
          conversationEntries: null
        });
      }
    };

    let newQuoteContainer: QuoteContainer | null = null;

    switch (_state) {
      case QuoteState.Rejected:
      case QuoteState.Cancelled:
        if (
          flagInSet(
            _original,
            QuoteState.Accepted | QuoteState.Accepted_AssignedToReviewer | QuoteState.SupplierReviewPending
          )
        ) {
          //If we are here we need to cancel a support review ticket.
          //TODO#- should actually reject all open support tickets
          await updateQuoteReviewTicket(BranchQuoteSupportStatus.Cancelled);
        }
        return true;
      case QuoteState.SupplierReviewed:
        await updateQuoteReviewTicket(BranchQuoteSupportStatus.Resolved);
        return true;
      case QuoteState.Approved:
        await updateQuoteReviewTicket(BranchQuoteSupportStatus.Resolved);
        return true;

      case QuoteState.SupplierReviewPending:
        newQuoteContainer = await this.makeAlternative(true);
        if (newQuoteContainer) {
          await getApiFactory()
            .project()
            .createProjectResourceReference({
              resourceId: newQuoteContainer.quote?.id ?? emptyGuid,
              typeOf: ResourceType.Quote,
              parentResourceId: emptyGuid,
              parentTypeOf: ResourceType.None,
              projectId: await this.projectId()
            });

          goURL(resourceQuote, newQuoteContainer.quoteId);
          return true;
        }
        break;
      case QuoteState.IssuePending:
        //moved to after change, because we must be in issuepending
        //before the azure function processes this message
        if (!(await this.sendIssueQuote())) return false;
        break;
      case QuoteState.Accepted:
        //TODO.. This is not triggering when supplier doe
        //Supplier Approved was set if this has been through a review process, so that we
        //dont come back and create another support ticket
        if (!this.quote.supplierApproved && this.isSupplierQuote) {
          await this.franchiseeApi.resolveBranchQuoteSupport({
            quoteSetId: this.quote.quoteSetId,
            resolutionReason: tlang`${'ref:resolve-open-tickets'}
                        %%support-request-resolved%% because %%quote%% ${getQuoteNumberFormatted(
                          this.quote
                        )} has been %%quote-state-accepted%%`
          });

          const bqsId = newGuid();
          await this.franchiseeApi.addBranchQuoteSupport({
            id: bqsId,
            masterDocumentConversationEntry: null,
            conversationId: bqsId,
            subject: tlang`%%supplier%% Review`,
            branchQuoteId: this.branchQuote.id,
            conversationEntry: null,
            status: BranchQuoteSupportStatus.New,
            type: BranchQuoteSupportItemType.QuoteReview
          });
        }
    }
    return true;
  }

  async saveQuote(silently?: boolean, siblings?: InputQuoteState[] | null): Promise<boolean> {
    try {
      this.quote.serviceProviderData = toJsonStr(this.container.quoteProviderData) ?? null;

      this.flushCaches();
      const result = await this.api.updateQuote({
        siblingQuoteState: siblings ?? null,
        quote: this.quote,
        quotePrice: this.quotePrice,
        numberSeed: this.numberSeed,
        stateChangeReason: this.stateChangeReason,
        quotePresentation: this.container.quotePresentation
      });

      const result1 = await this.franchiseeApi.updateBranchQuote({
        branchQuote: this.branchQuote
      });
      if (result) {
        if (result1)
          await this.resetBranchQuote(
            result.quote!,
            result.resultFullQuotePrice!.quotePrice!,
            result.quotePresentation,
            result1.branchQuote!,
            null
          );
        else await this.resetQuote(result.quote!, result.resultFullQuotePrice!.quotePrice!, null, result.siblings);
        await this.fetchCache();
        this.updateItemPrices(result.resultFullQuotePrice!.itemPrices);
        this.updateItemBuyIns(result.resultFullQuotePrice!.itemBuyIns);

        if (result1 && result) {
          await this.doAfterSave();
          if (!silently) autoSaveNotifier.triggerAutoSaveSuccess();

          return true;
        }
      }
      return false;
    } finally {
      this.numberSeed = null;
    }
  }

  quoteChanged(): boolean {
    return super.quoteChanged() || !compare(this.franchiseeBackup.branchQuote, this.franchiseeContainer.branchQuote);
  }

  protected async copyQuoteItemBefore(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
    if (!isSupplierUsingSSI(this.quote.supplierId)) {
      return true;
    }
    this.saveModal = new SaveWorkflowModal(
      tlang`Copying ${quoteItemContainer.item.title}`,
      this.informationDispatcher,
      3
    );
    this.modalState = await this.saveModal?.show();
    await this.modalState.onShow;

    return true;
  }
  protected async copyQuoteItemFinally(
    _quoteItemContainer: QuoteItemContainer,
    _newQuoteItemContainer: QuoteItemContainer | null
  ): Promise<void> {
    this.saveModal?.done();
    await this.saveModal?.hideModal();
    this.saveModal = undefined;
    this.modalState = undefined;
  }
  public ssiProcessor(itemFilter?: string[] | undefined, extaItems?: QuoteItemContainer[]): SSIProcessor {
    return new V6SSIProcessor(this, itemFilter, v => this.saveQuoteCalculations(v), extaItems);
  }

  protected async copyQuoteItemAfter(
    _quoteItemContainer: QuoteItemContainer,
    newQuoteItemContainer: QuoteItemContainer
  ): Promise<boolean> {
    if (newQuoteItemContainer) {
      await this.runAndSaveSSI();
    }
    return true;
  }
  saveQuoteCalculations(v: QCQuoteValues | null | undefined) {
    const data = this.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.quoteCalculations = v ?? undefined;
  }

  protected async getSupportReview(): NullPromise<BranchQuoteSupport> {
    const support = await this.franchiseeApi.getBranchQuoteSupport({
      quoteSetId: this.branchQuote.quoteSetId,
      branchQuoteSupportId: null,
      quoteId: this.quote.id
    });

    if (!support || !Array.isArray(support.items)) return null;

    const quoteReviewItems = support.items.filter(item => item.type === BranchQuoteSupportItemType.QuoteReview);

    return this.getLatestItem(quoteReviewItems);
  }

  private getLatestItem(items: BranchQuoteSupport[]): BranchQuoteSupport | null {
    if (items.length === 0) return null;

    items.sort((itemA, itemB) => itemB.dateCreated.localeCompare(itemA.dateCreated));
    return items[0];
  }

  protected async internalMakeCopy(
    asAlternate = false,
    alternativeType = QuoteAlternativeType.Standard
  ): NullPromise<QuoteContainer> {
    await this.needsQuote();
    await this.needsQuoteItems();
    if (!this.branchQuote || !this.quote) return null;

    if (this.numberSeed && this.quoteState == QuoteState.Draft) this.quoteState = QuoteState.Active;

    //we need to remove special items from the list as ETO are not useful in a copy.
    const specialOrderItemIds =
      this.container.items
        ?.filter(item => {
          return isSpecialItem(item);
        })
        .map(item => item.id) ?? [];

    try {
      if (!asAlternate) this.quote.quoteSetId = newGuid();

      const quoteResult = await this.api.duplicateQuote({
        excludedQuoteItems: asAlternate ? null : specialOrderItemIds,
        quoteId: this.quote.id,
        asAlternate: asAlternate,
        alternativeType: asAlternate ? alternativeType : QuoteAlternativeType.Standard,
        validUntilDate: localDateToServer(today(28)),
        //Alternates use the existing number, seeds are only used by new quotes
        numberSeed: asAlternate ? null : this.numberSeed,
        quoteCustomerId: asAlternate ? this.quote.quoteCustomerId : null,
        title: asAlternate ? `${this.quote.title}` : null,
        quoteState: !asAlternate
          ? this.numberSeed
            ? QuoteState.Active
            : QuoteState.Draft
          : alternativeType == QuoteAlternativeType.Standard
            ? QuoteState.Active
            : QuoteState.SupplierReviewPending
      });

      if (quoteResult) {
        //updating a copy may have changed properties of the existing quote
        if (this.container.quote) Object.assign(this.container.quote, quoteResult.source);
        this.backup.quote = clone(this.container.quote);

        const newBranchQuote = clone(this.branchQuote);
        newBranchQuote.dateCreated = localDateTimeToServer(new Date());

        //if the new quote is a draft quote, remove the client & contact information
        if (quoteResult.quote.state === QuoteState.Draft) {
          newBranchQuote.clientId = emptyGuid;
          newBranchQuote.clientName = '';
          newBranchQuote.contactId = emptyGuid;
          newBranchQuote.contactName = '';
          newBranchQuote.clientTypeId = emptyGuid;
        }

        newBranchQuote.id = quoteResult.quote.id;
        newBranchQuote.quoteSetId = quoteResult.quote.quoteSetId;

        const branchQuoteResult = await this.franchiseeApi.createBranchQuote({
          branchQuote: newBranchQuote
        });

        if (!branchQuoteResult) {
          await this.api.deleteQuote({
            quoteId: quoteResult.quote.id
          });
          return null;
        }

        //we need to move the support ticket to this new item if it exists
        if (asAlternate && alternativeType == QuoteAlternativeType.SupplierReview) {
          const supportReview = await this.getSupportReview();
          if (supportReview)
            await this.franchiseeApi.updateBranchQuoteSupport({
              branchQuoteSupportId: supportReview.id,
              masterDocumentConversationId: null,
              branchQuoteId: newBranchQuote.id,
              status: null,
              subject: null,
              quoteItemIdToDetach: null,
              conversationEntries: null
            });
        }

        const container = new FranchiseeQuoteContainer(
          quoteResult.quote.id,
          quoteResult.quote,
          quoteResult.quotePrice,
          quoteResult.quotePresentation, //QUOTE PRESENTATION SHOULD BE HERE
          quoteResult.siblings,
          null,
          null,
          null,
          null,
          branchQuoteResult.branchQuote
        );

        //Draft quotes (thus a draft copy) cannot have a support ticket or ETO
        if (asAlternate && specialOrderItemIds.length > 0)
          await this.copyCustomItemDetailsToNewAlternateQuote(
            container,
            this.container.items?.filter(item => {
              return isSpecialItem(item);
            }) ?? [],
            alternativeType == QuoteAlternativeType.SupplierReview
          );

        return container;
      }
    } finally {
      this.numberSeed = null;
    }
    return null;
  }
  public async makeCopy(): NullPromise<QuoteContainer> {
    return await this.internalMakeCopy(false);
  }

  public async makeAlternative(forSupplierReview = false): NullPromise<QuoteContainer> {
    return await this.internalMakeCopy(
      true,
      forSupplierReview ? QuoteAlternativeType.SupplierReview : QuoteAlternativeType.Standard
    );
  }

  public async deleteQuote(): Promise<boolean> {
    await this.needsQuote();
    const quoteItemImagePaths =
      this.container.items
        ?.filter(quoteItem => !isEmptyOrSpace(quoteItem.virtualThumbnailPath))
        .map(quoteItem => ({
          virtualPath: quoteItem.virtualThumbnailPath
        })) ?? [];
    await Promise.all([
      this.api.deleteQuote({ quoteId: this.quote.id }),
      this.franchiseeApi.deleteBranchQuote({
        branchQuoteId: this.quote.id
      }),
      this.blobApi.deleteFilesByVirtualPath({
        fileItems: quoteItemImagePaths
      })
    ]);

    return true;
  }

  override async getBuyInCosts(buyInItems?: QuoteFrameBuyInItem[]): Promise<StockLookupViewExtra[] | undefined> {
    if (!buyInItems || buyInItems.length === 0) return undefined;

    const supplierId = this.quote.supplierId;

    const constructLookup = (buyin: QuoteFrameBuyInItem) => {
      const code = buyin.code;
      const libCode = buyin.extraDetails?.['LibCode'] ?? '';
      const suppCode = buyin.extraDetails?.['SuppCode'] ?? '';

      return new (class implements LookupStockItem {
        supplierId = supplierId;
        code = code;
        libCode = libCode;
        supplierCode = suppCode;
      })();
    };

    const stock = await this.franchiseeApi.lookupStock({
      lookup: buyInItems.map(x => constructLookup(x))
    });

    if (!stock) return [];

    return buyInItems.map(x => {
      const item = constructLookup(x);

      // Once V6 returns the LibCode, we need to filter on that as well
      const stockItem = stock.stockItems.find(
        y => y.code == item.code && y.supplierCode == item.supplierCode && y.supplierId == item.supplierId
      );

      if (!stockItem)
        return {
          code: item.code,
          cost: null,
          description: x.decription,
          libCode: item.libCode,
          supplierCode: item.supplierCode,
          supplierId: item.supplierId,
          version: '',
          lookup: `Length=${x.extraDetails?.['Length']};Width=${x.extraDetails?.['Width']};Height=${x.extraDetails?.['Height']}`,
          marginPercentage: null,
          calculatedGross: null
        };

      const isMetric = isSupplierUnitsMetric(item.supplierId);
      const toMetricConversion = 25.4;

      //For metric, we are storing cost per m, we need to convert that to cost per mm. For imperial, we are storing values per foot
      //and need to convert that to cost per inch (1m = 1000mm, 1ft = 12inches)
      const costlength = (stockItem.cost ?? 0) / (isMetric ? 1000 : 12);
      const costArea = (stockItem.cost ?? 0) / (isMetric ? 1000 * 1000 : 12);

      const length =
        (parseFloat(x.extraDetails?.['Length'] ?? '') || 0) * (isMetric ? toMetricConversion : 1) * costlength;
      const area =
        (parseFloat(x.extraDetails?.['Width'] ?? '') * parseFloat(x.extraDetails?.['Height'] ?? '') || 0) *
        (isMetric ? toMetricConversion * toMetricConversion : 1) *
        costArea;

      //Individual prices are stored: cost = qty * individual cost
      const each = parseFloat(x.extraDetails?.['PackSize'] ? x.quantity.toString() : '') || 0;

      return {
        code: stockItem.code,
        cost: money((length + area + each) * x.quantity, 2),
        description: stockItem.description,
        libCode: stockItem.libCode,
        supplierCode: stockItem.supplierCode,
        supplierId: stockItem.supplierId,
        version: stockItem.version,
        lookup: `Length=${x.extraDetails?.['Length'] ?? ''};Width=${
          x.extraDetails?.['Width'] ?? ''
        };Height=${x.extraDetails?.['Height'] ?? ''}`,
        marginPercentage: null,
        calculatedGross: null
      };
    });
  }

  protected flushCaches() {
    const siblingIds = this.siblings?.map(x => x.quoteId) ?? [];
    cache().quote.flush([this.quoteId, ...siblingIds]);
    cache().projectResourceLink.flush([this.quoteId, ...siblingIds]);
  }

  protected internalIsReadonly(): boolean {
    const stateHandler = getQuoteStateEngine(this);

    const canEdit = stateHandler.isQuoteEditable();

    return !canEdit;
  }

  protected async resetBranchQuote(
    quote: Quote,
    quotePrice: QuotePrice,
    quotePresentation: QuotePresentation | null,
    branchQuote: BranchQuote,
    siblings: QuoteSetSibling[] | null
  ) {
    await this.resetQuote(quote, quotePrice, quotePresentation, siblings);
    this.franchiseeContainer.branchQuote = branchQuote;
    this.franchiseeBackup.branchQuote = this.clone(branchQuote);
  }

  async copyCustomItemDetailsToNewAlternateQuote(
    newQuoteContainer: FranchiseeQuoteContainer,
    customItemRequests: QuoteItem[],
    isSupplierReview: boolean
  ) {
    const m = new QuoteContainerManager(newQuoteContainer, getApiFactory().quote(), getApiFactory().blob());
    await m.needsQuoteItems();
    const support = await this.needsQuoteSupport(true);
    await cache().quoteItemConversation.preFetch(customItemRequests.map(x => x.id));
    const api = getApiFactory().franchisee();
    if (m.container.items)
      for (let i = 0; i < m.container.items.length; i++) {
        const item = m.container.items[i];
        const originalItem = customItemRequests.find(x => x.commonId === item.commonId);
        if (originalItem) {
          const conversationId = cache()
            .quoteItemConversation.getLocalData(originalItem.id)
            ?.byType(QuoteItemConversationType.EngineeredToOrder);
          const bqs = support?.items.find(
            x => x.conversationId === conversationId && x.type === BranchQuoteSupportItemType.EngineeredToOrder
          );
          if (conversationId !== undefined && bqs !== undefined) {
            await api.duplicateBranchQuoteSupport({
              branchQuoteSupportId: bqs.id,
              originalQuoteItemId: originalItem.id,
              quoteItemConversationType: QuoteItemConversationType.EngineeredToOrder,
              quoteItemId: item.id,
              branchQuoteId: m.quote.id,
              status: isSupplierReview ? bqs.status : BranchQuoteSupportStatus.Draft,
              conversationEntries: [
                {
                  attachments: [],
                  id: newGuid(),
                  text: tlang`${'ref:quoteConversationCopied'}
This %%special-item%% has been duplicated from its original %%quote%% as an alternative:
${getQuoteNumberFormatted(this.quote, false)}                
#${this.itemPosition(originalItem.id)} ${firstValidString(originalItem.title, tlang`%%special-item%%`)}
                    `,
                  users: []
                }
              ]
            });
          }
        }
      }
  }
}
