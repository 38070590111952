import { lapsedMilliseconds } from '../components/time';

export interface IEventCancellation {
  cancelled: boolean;
  reset: () => void;
}
export class EventCancellation implements IEventCancellation {
  private _cancelled: boolean = false;

  get cancelled(): boolean {
    return this._cancelled;
  }
  cancel() {
    this._cancelled = true;
  }
  reset() {
    this._cancelled = false;
  }
}
export interface IStagedEventOptions {
  event: () => void | Promise<void>;
  threshold?: number;
  title?: string;
  eventFinally?: () => void;
  eventTriggered?: () => void | Promise<void>;
  eventActioned?: () => void | Promise<void>;
  cancelToken?: IEventCancellation;
  testInterval?: number;
}
export const defaultStagedThreshold = 15000;
export const defaultTestInterval = 1000;
//staged execution should only be used in cases where recieving a message is
//enough info and the data is not important
export function createStagedEvent(options: IStagedEventOptions): () => void {
  let intervalms = options.testInterval ?? defaultTestInterval;
  //create a closure that will execute on a timed interval
  let timer: NodeJS.Timeout | undefined = undefined;
  let triggered: Date | undefined = undefined;
  let actioned: Date | undefined = undefined;
  let actionCount = 0;
  let executing = false;
  let restart = false;
  const reset = () => {
    clearInterval(timer);
    timer = undefined;
    triggered = undefined;
    actionCount = 0;
    actioned = undefined;
  };
  const intervalEvent = async () => {
    if (options.cancelToken?.cancelled) {
      reset();
      options.cancelToken?.reset();
      options.eventFinally?.();
      return;
    }
    const ms = lapsedMilliseconds(triggered!);
    if (ms >= (options.threshold ?? defaultStagedThreshold)) {
      try {
        executing = true;
        await options.event();
        options.eventFinally?.();
      } finally {
        executing = false;
        reset();
        if (restart) startProcess();
      }
      return;
    }

    timer = setTimeout(intervalEvent, intervalms);
  };
  return () => {
    if (!triggered) {
      startProcess();
    } else {
      if (executing) restart = true;
      actionCount++;
      actioned = new Date();
      options.eventActioned?.();
      console.log(
        `Triggered Event '${options.title ?? 'no name'}' actioned ${actionCount} since ${triggered.toLocaleTimeString()}, at ${actioned.toLocaleTimeString()}`
      );
    }
  };

  function startProcess() {
    executing = false;
    restart = false;
    options.cancelToken?.reset();
    triggered = new Date();
    actionCount++;
    actioned = triggered;
    options.eventTriggered?.();
    options.eventActioned?.();
    timer = setTimeout(intervalEvent, intervalms);
    console.log(`Triggered Event '${options.title ?? 'no name'}' started ${triggered.toLocaleTimeString()}`);
  }
}
