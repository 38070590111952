import { PurchaseOrderContainerManager } from '../data/purchase-order-container';
import { PurchaseOrderItem, PurchaseOrderState } from '../../api/dealer-api-interface-franchisee';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { QuoteItem } from '../../api/dealer-api-interface-quote';
import { getApiFactory } from '../../api/api-injector';
import { emptyGuid } from '../../api/guid';
import { moneyToHtml } from '../../components/currency-formatter';

// eslint-disable-next-line import/named
import { html, render, TemplateResult } from 'lit';
import { flagInSet, isEmptyOrSpace } from '../../components/ui/helper-functions';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { DevelopmentError } from '../../development-error';
import { EventTemplate } from '../../components/ui/events';
import { ViewBase } from '../../components/ui/view-base';
import { lang, tlang } from '@softtech/webmodule-components';
import { isFrame, isSpecialItem, quoteItemThumbSrc } from '../../quotes/data/quote-helper-functions';
import { getV6QuoteItemFrameDimensions } from '../../v6config/v6-ui';
import { getFrameDetailsTemplate } from '../../frames/ui';
import { Frame, FrameValidationType, Snippet } from '@softtech/webmodule-data-contracts';
import { QuoteItemContainer } from '../../quotes/data/quote-item-container';
import {
  getV6FrameForContainer,
  getV6QuoteItemForContainer,
  isQuoteFrameVersionCurrent,
  isV6
} from '../../quotes/data/v6/helper-functions';
import { customElement } from 'lit/decorators.js';

interface PurchaseOrderItemTableOptions {
  purchaseOrderManager: PurchaseOrderContainerManager;
}

interface PurchaseOrderLineItem {
  purchaseOrderItem: PurchaseOrderItem;
  qic: QuoteItemContainer;
}

@customElement('wm-purchaseorderitemtable')
export class PurchaseOrderItemTable extends DataTableWrapper<PurchaseOrderLineItem> {
  purchaseOrderManager: PurchaseOrderContainerManager;
  quoteApi = getApiFactory().quote();
  lineItems: PurchaseOrderLineItem[] = [];

  constructor(options: PurchaseOrderItemTableOptions) {
    super();
    this.purchaseOrderManager = options.purchaseOrderManager;
  }
  public async refreshData(sinceLastRefreshMinutes?: number): Promise<void> {
    return super.refreshData(sinceLastRefreshMinutes);
  }

  override verticalHeight(): string | undefined {
    return undefined;
  }

  override getDefaultSortAsc(): boolean {
    return false;
  }

  usePaging(): boolean {
    return false;
  }

  useInfo(): boolean {
    return true;
  }

  pageLength(): number {
    return 10000;
  }

  override getDefaultSortFieldIndex(_columns: any[]): number {
    return _columns.findIndex(c => c.data === 'id');
  }

  override afterRowCreated(_row: any, _data: any, _dataIndex: any) {
    //        const rowClass = dataIndex % 2 === 0 ? "odd-row" : "even-row";
    //       $(row).addClass(rowClass);
  }

  override async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<PurchaseOrderLineItem>> {
    return {
      count: this.lineItems.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: this.lineItems.length,
      results: this.lineItems
    };
  }

  override getColumns(): any[] {
    const purchaseOrderItemTitleDisplay = (item: QuoteItem) => {
      let title = item.title;
      if (isEmptyOrSpace(title)) {
        title = tlang`%%frame-title%%`;
      }
      return `<span class="quote-item-title">${this.htmlEncode(title)}</span><br/>
                    <span class="quote-item-description">${this.htmlEncode(item.description)}</span>`;
    };

    return [
      {
        title: 'Item #',
        width: '60px',
        data: 'id',
        className: 'order-item-row',
        render: (_value: string, _type: never, row: PurchaseOrderLineItem, meta: any) => {
          return row.qic.item.isRestrictedToPowerUser
            ? `${meta.row + 1} <span class="power-user"></span>`
            : `${meta.row + 1}`;
        }
      },
      {
        title: 'Image',
        width: '50px',
        data: 'id',
        className: 'order-item-image',
        render: (_value: string, _type: never, row: PurchaseOrderLineItem) => {
          if (row.qic.item.virtualThumbnailPath === '') {
            const src = quoteItemThumbSrc(row.qic.item);

            return `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" alt="Shipping item thumbnail" /> `;
          }

          const src = this.quoteApi.api.fullUrl(`api/file/${row.qic.item.virtualThumbnailPath}`);
          if (_type === 'filter') {
            return '1';
          } else if (_type === 'display') {
            return `<img class="quote-item-thumbnail" src='${src}' style="width:48px; height:48px" alt="Line item thumbnail" /> `;
          } else return src;
        }
      },
      {
        title: tlang`%%frame-title%% and Description`,
        width: '200px',
        data: 'title',
        className: 'order-item-title',
        render: (_data: string, _type: any, row: PurchaseOrderLineItem, _meta: any) =>
          purchaseOrderItemTitleDisplay(row.qic.item)
      },
      {
        title: 'Options',
        width: '200px',
        data: 'description',
        className: 'order-item-description',
        render: (_data: any, _type: any, row: PurchaseOrderLineItem, meta: any) => {
          try {
            if (isFrame(row.qic.item) || isSpecialItem(row.qic.item))
              return this.generateQuoteItemOptionsView(row, this.dataTable.row(meta.row).child.isShown());
            else if (row.qic.item.providerReferenceId === emptyGuid) {
              return row.qic.item.description;
            } else return '';
          } catch {
            return '';
          }
        }
      },
      {
        title: 'Qty',
        width: '50px',
        data: 'quantity',
        className: 'order-item-quantity',
        render: (_data: any, _type: any, row: PurchaseOrderLineItem, _meta: any) => row.qic.item.quantity
      },
      {
        title: 'Price',
        width: '90px',
        data: 'id',
        render: (_data: any, _type: any, row: PurchaseOrderLineItem, _meta: any) => {
          const price = row.qic.price.supplierNettQuantityCost + row.qic.price.supplierPriceAdjustment;
          return moneyToHtml(price);
        },
        className: 'dt-right order-item-price'
      }
    ];
  }

  public isOutOfDate(): boolean {
    for (let i = 0; i < this.lineItems.length; i++) {
      const item = this.lineItems[i];
      if (isFrame(item.qic.item) || isSpecialItem(item.qic.item)) {
        const state = this.getItemValidationType(item);
        if (state === FrameValidationType.outOfDate) return true;
      }
    }
    return false;
  }

  generateQuoteItemOptionsView(lineItem: PurchaseOrderLineItem, childVisible: boolean) {
    const validationIndicator = () => {
      const state = this.getItemValidationType(lineItem);
      switch (state) {
        case FrameValidationType.note:
          return `<i class="fa-solid fa-message text-primary validation-icon me-1"></i>`;
        case FrameValidationType.information:
          return `<i class="fa-solid fa-circle-info text-primary validation-icon me-1"></i>`;
        case FrameValidationType.warning:
          return `<i class="fa-solid fa-triangle-exclamation text-warning validation-icon me-1"></i>`;
        case FrameValidationType.critical:
          return `<i class="fa-solid fa-circle-exclamation text-danger validation-icon me-1"></i>`;
        case FrameValidationType.outOfDate:
          return `<i class="fa-solid fa-wrench text-danger me-1"></i>`;
      }
      return ``;
    };

    if (isSpecialItem(lineItem.qic.item) && isEmptyOrSpace(lineItem.qic.item.comment))
      return `<span>${this.getOptionsOneLiner(lineItem)}</span><span class="float-end">${validationIndicator()}</span>`;

    if (childVisible)
      return `<span>${this.getOptionsOneLiner(
        lineItem
      )}</span><span class="float-end options-dropdown">${validationIndicator()}<i class="fa-solid fa-caret-up"></i></span>`;
    else
      return `<span>${this.getOptionsOneLiner(
        lineItem
      )}</span><span class="float-end options-dropdown">${validationIndicator()}<i class="fa-solid fa-caret-down"></i></span>`;
  }
  getItemValidationType(lineItem: PurchaseOrderLineItem): FrameValidationType {
    //we are currently only supporting items that are for v6
    if (!isV6(lineItem.qic.item)) return FrameValidationType.nothing;

    const data = this.getFrameData(lineItem);
    if (!data) return FrameValidationType.nothing;
    const quoteItemContainer: QuoteItemContainer = lineItem.qic;

    if (
      flagInSet(this.purchaseOrderManager.purchaseOrder.state, PurchaseOrderState.Draft) &&
      !isQuoteFrameVersionCurrent(quoteItemContainer)
    )
      return FrameValidationType.outOfDate;
    return FrameValidationType.nothing;
  }

  getOptionsOneLiner(lineItem: PurchaseOrderLineItem) {
    //only proceed if this is not a frame else return the description
    if (!isV6(lineItem.qic.item)) return lineItem.qic.item.description;

    const data = getV6QuoteItemForContainer(lineItem.qic);
    return getV6QuoteItemFrameDimensions(data);
  }

  getExtendedDetails(lineItem: PurchaseOrderLineItem) {
    if (isFrame(lineItem.qic.item)) return this.getFrameExtendedDetails(lineItem);
    else if (isSpecialItem(lineItem.qic.item)) return this.getSpecialItemExtendedDetails(lineItem);

    return '';
  }
  private getFrameData(lineItem: PurchaseOrderLineItem): Frame | undefined {
    if (!isV6(lineItem.qic.item)) return undefined;
    return getV6FrameForContainer(lineItem.qic);
  }

  getFrameExtendedDetails(lineItem: PurchaseOrderLineItem) {
    if (!isV6(lineItem.qic.item)) return '';

    const data = this.getFrameData(lineItem);
    const element = document.createElement('div');
    if (data) {
      const template = html` <div class="extended-options-line">${getFrameDetailsTemplate(data, true)}</div>`;
      render(template, element);
    }
    return element.innerHTML;
  }

  protected bindClickEvents($dataTable: any) {
    $dataTable.on(
      'click',
      '.options-dropdown',
      this.eventHandler(async (data: PurchaseOrderLineItem, _ev: any, row: any, tr: any) => {
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
          row.invalidate();
        } else {
          // Open this row
          const content = this.getExtendedDetails(data);
          if (!isEmptyOrSpace(content)) {
            const className = row.index() % 2 == 0 ? 'odd' : 'even';
            row.child(content, className).show();
            row.invalidate();
            tr.addClass('shown');
          }
        }
      })
    );
  }

  protected override ordering(): boolean {
    return false;
  }

  private getSpecialItemExtendedDetails(lineItem: PurchaseOrderLineItem) {
    if (!(isSpecialItem(lineItem.qic.item) && !isEmptyOrSpace(lineItem.qic.item.comment))) return '';

    const element = document.createElement('div');

    const warningTemplate = (): TemplateResult => {
      if (!isEmptyOrSpace(lineItem.qic.item.description)) return html``;

      return html`
        <ul class="list-group">
          <li class="list-group-item list-group-item-warning">
            <span class="me-3 fw-bold">Reference</span>
            ${lang('Please provide a reference')}
          </li>
        </ul>
      `;
    };

    const template = html` <div class="extended-options-line">
      <div style="white-space: pre-wrap">${lineItem.qic.item.comment}</div>
      ${warningTemplate()}
    </div>`;
    render(template, element);
    return element.innerHTML;
  }
}

export interface PurchaseOrderDetailViewOptions {
  purchaseOrderManager: PurchaseOrderContainerManager;
  purchaseOrderCache: DataCacheGeneric;
}
@customElement('wm-purchaseorderdetailview')
export class PurchaseOrderDetailView extends ViewBase {
  protected readonly purchaseOrderManager: PurchaseOrderContainerManager;
  protected table: PurchaseOrderItemTable;
  protected purchaseOrderCache: DataCacheGeneric;
  lineItems: PurchaseOrderLineItem[] = [];
  constructor(options: PurchaseOrderDetailViewOptions) {
    super();
    this.purchaseOrderManager = options.purchaseOrderManager;
    this.purchaseOrderCache = options.purchaseOrderCache;
    this.table = this.ItemTableFactory();
  }
  public isOutOfDate(): boolean {
    return this.table.isOutOfDate();
  }
  public async prepareForSave() {
    throw new DevelopmentError('items-view, Method not implemented.');
  }
  async afterConstruction(): Promise<void> {
    await this.loadLineItems();
  }
  public async loadLineItems() {
    await this.purchaseOrderManager.needsPurchaseOrder();
    const quoteApi = getApiFactory().quote();

    // NeedsPurchaseOrder will get the PO and its Items from the server.
    const itemKeys = this.purchaseOrderManager.container.items?.map(x => x.quoteItemId) ?? [];

    const quoteItemSummaries = await quoteApi.getQuoteItemsSummaryById({
      quoteItemId: itemKeys
    });
    this.lineItems = [];
    if (!quoteItemSummaries || !itemKeys) return;

    const lineItems = itemKeys.map(x => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const item = quoteItemSummaries.items.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = quoteItemSummaries.data.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const price = quoteItemSummaries.prices.find(y => y.id == x)!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const purchaseOrderItem = this.purchaseOrderManager.container.items?.find(y => y.quoteItemId == x);
      if (!purchaseOrderItem) throw new DevelopmentError('missing order item is not expected');
      const lineItem: PurchaseOrderLineItem = {
        purchaseOrderItem,
        qic: { item, data, price }
      };
      return lineItem;
    });

    const result = lineItems.filter(Boolean);
    this.lineItems = result;
    this.table.lineItems = result;
  }
  public getValidationErrors(): string[] {
    return [];
  }

  public async refreshData(): Promise<void> {
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
    await this.loadLineItems();
    await this.table.refreshData();
    this.requestUpdate();
  }

  public async invalidate(): Promise<void> {
    this.requestUpdate();
    this.table.invalidate();
  }

  public buttonMenu(): Snippet {
    return html``;
  }

  protected ItemTableFactory(): PurchaseOrderItemTable {
    return new PurchaseOrderItemTable({
      purchaseOrderManager: this.purchaseOrderManager
    });
  }

  protected template(): EventTemplate {
    return html` <div>${this.table}</div>`;
  }
}
